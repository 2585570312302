<script setup lang="ts">
import { email, maxLength, required } from '@vuelidate/validators';
import {
  AmountType,
  CurrencyPair,
  CutoffTimeModel,
  ForwardQuotePriceRequest,
  FxOperation,
  HedgingInstruments,
  NonTradeableDays,
  TimeFrames,
  VanillaPriceResponse,
} from 'ah-api-gateways';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import {
  getChildModel,
  getState,
  makeFormModel,
  resetForm,
  setState,
  toDataModel,
  updateModel,
} from 'ah-common-lib/src/form/helpers';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import {
  emailField,
  financialAmountField,
  radioField,
  selectField,
  separator,
  singleInputDateField,
  textAreaField,
  textField,
} from 'ah-common-lib/src/form/models';
import {
  date,
  disallowStateDates,
  ifTest,
  maxDate,
  minDate,
  requiredIfStateValue,
  validName,
} from 'ah-common-lib/src/form/validators';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from 'ah-trades';
import { addDays, addMonths, addQuarters, addYears, format, startOfDay, addMinutes } from 'date-fns';
import { computed, PropType, reactive, ref, watch } from 'vue';
import FormattedCurrency from 'ah-trades/src/components/limitsAndCollateral/FormattedCurrency.vue';
import { getServices } from '@/app/services';
import { ApiError, GenericErrorCodes, PricingEngineErrorCodes } from 'ah-requests';
import { forkJoin } from 'rxjs';
import { stripZoneOffset } from 'ah-common-lib/src/helpers/time';
import UpdatingInTimer from 'ah-common-lib/src/common/components/UpdatingInTimer.vue';
import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import { PaymentFormDetails, PaymentScheduleFrequency } from '@/app/models/payments';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useToast } from 'ah-common-lib/src/toast';

const emit = defineEmits({
  'update:solution': (_solution: VanillaPriceResponse) => true,
  'update:model': (_model: PaymentFormDetails) => true,
  'update:disabledDates': (_disabledDates: string[]) => true,
  'update:isInvalid': (_isInvalid: boolean) => true,
  'update:isLoading': (_isLoading: boolean) => true,
  next: () => true,
});

const props = defineProps({
  solution: {
    type: Object as PropType<VanillaPriceResponse>,
  },
  model: {
    type: Object as PropType<PaymentFormDetails>,
  },
});

const requestManager = useRequestManager().manager;

const toast = useToast();

const tradeState = useTradeState();

const onBehalfOfClient = useOnBehalfOf();

const services = getServices();

const currencies = ref<CurrencyPair[]>([]);

const sellCurrencies = ref<string[]>([]);

const buyCurrencies = ref<string[]>([]);

const holidays = ref<string[]>([]);

const weekends = ref<string[]>([]);

const strikeRate = ref<number>();

const sellAmount = ref<number>(0);

const targetDate = ref<Date>();

const cutoffTime = ref<CutoffTimeModel>();

const vanillaSolution = ref<VanillaPriceResponse>();

const debouncedPrices = ref<DebouncedFunc<() => void>>();

const minForwardDate = ref<Date>(calcMinForwardDate([]));

const paymentFrequencyOptions = [
  { value: PaymentScheduleFrequency.MONTHLY, label: 'Monthly' },
  { value: PaymentScheduleFrequency.QUARTERLY, label: 'Quarterly' },
];

const paymentFrequencyAnuallyOptions = [
  { value: PaymentScheduleFrequency.BIANNUAL, label: 'Bi-annually' },
  { value: PaymentScheduleFrequency.ANNUAL, label: 'Annually' },
];

const invoiceDetailsForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'invoiceDetailsForm',
    fieldType: 'form',
    fields: [
      selectField('buyCurrency', 'Invoice Currency', [], {
        placeholder: 'Select Currency',
        fieldWrapperClass: 'col col-6 col-lg-4 align-self-end',
        showRequiredMarkers: true,
        required: true,
      }),
      financialAmountField('buyAmount', 'Total Invoice Amount', {
        placeholder: '0.00',
        fieldWrapperClass: 'col col-6 col-lg-4 align-self-end',
        required: true,
        showRequiredMarkers: true,
      }),
      separator(),
      radioField('mvpFrequency', 'Frequency', paymentFrequencyOptions, {
        defaultValue: PaymentScheduleFrequency.MONTHLY,
        fieldWrapperClass: 'radio-form-field col col-6 col-lg-2 ',
        required: true,
        showRequiredMarkers: true,
      }),
      radioField('frequencyAnually', '', paymentFrequencyAnuallyOptions, {
        inline: false,
        fieldWrapperClass: 'radio-form-field col col-6 col-lg-2  d-flex align-items-end',
        required: false,
        readonly: true,
      }),

      selectField('mvpNumPayments', 'Number of Payments', [], {
        placeholder: 'Select',
        fieldWrapperClass: 'col col-12 col-lg-2 col-md-6',
        required: true,
        showRequiredMarkers: true,
      }),

      singleInputDateField(
        'mvpFirstPaymentDate',
        'First Payment Date',
        {
          required: true,
          placeholder: 'Select Date',
          fieldWrapperClass: 'col col-12 col-lg-4  col-md-6',
          showRequiredMarkers: true,
          errorMessages: {
            minDate: 'Earliest possible first payment date is T+3',
            maxDate: 'Settlement date is too far in the future, please try again',
            required: 'Required field',
            holidays: 'Settlement date cannot be on a holiday, please try again',
            weekend: 'Settlement date cannot be on a weekend, please try again',
          },
        },
        {
          required: ifTest(required, (val) => !val && !(val instanceof Date)),
          minDate: minDate(() => minForwardDate.value),
          maxDate: maxDate(() => addYears(new Date(), 1)),
          date: ifTest(date, (val) => val instanceof Date),
          holidays: ifTest(
            disallowStateDates('mvpFirstPaymentDate', 'holidays'),
            minDate(() => minForwardDate.value).$validator as (value: any, siblingState: any, vm: any) => boolean
          ),
          weekend: ifTest(
            disallowStateDates('mvpFirstPaymentDate', 'weekends'),
            minDate(() => minForwardDate.value).$validator as (value: any, siblingState: any, vm: any) => boolean
          ),
        }
      ),
    ],
  }),
  validation: null,
});

const customerPaymentForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'customerPaymentForm',
    fieldType: 'form',
    fields: [
      selectField('sellCurrency', `Customer's Currency`, [], {
        placeholder: 'Select Currency',
        required: true,
        showRequiredMarkers: true,
      }),
    ],
  }),
  validation: null,
});

const paymentRequestForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'paymentRequestForm',
    fieldType: 'form',
    fields: [
      emailField(
        'mvpCustomerEmail',
        'Customer Email Address',
        {
          required: true,
          fieldWrapperClass: 'col col-12 col-lg-8',
          placeholder: 'Email Address',
          showRequiredMarkers: true,
        },
        {
          email,
          required: requiredIfStateValue('mvpCustomerEmail'),
        }
      ),
      textField(
        'mvpInvoiceNumber',
        'Invoice Number/Reference',
        { required: true, fieldWrapperClass: 'col col-12 col-lg-4', placeholder: '00000', showRequiredMarkers: true },
        { required: requiredIfStateValue('mvpInvoiceNumber'), validName, maxLength: maxLength(50) }
      ),
      textAreaField(
        'mvpDescription',
        'Description',
        {
          fieldWrapperClass: 'col col-12',
          rows: 5,
          required: false,
          defaultValue: null,
          emptyValue: null,
          placeholder: 'You can add a short description to the payment schedule',
        },
        {
          maxLength: maxLength(500),
        }
      ),
    ],
  }),
  validation: null,
});

const clientId = computed(() => {
  return onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id;
});

const isValid = computed(() => !invoiceDetailsForm.validation?.$invalid && !customerPaymentForm.validation?.$invalid);

const isInvalid = computed(
  () =>
    invoiceDetailsForm.validation?.$invalid ||
    customerPaymentForm.validation?.$invalid ||
    paymentRequestForm.validation?.$invalid ||
    getState(getChildModel(invoiceDetailsForm.form, 'buyAmount')!, 'errors', []).length > 0 ||
    sellAmount.value === 0 ||
    !strikeRate.value ||
    !cutoffTime.value
);

const sellCurrency = computed(() => customerPaymentForm.form.sellCurrency);

const buyCurrency = computed(() => invoiceDetailsForm.form.buyCurrency);

const dividedLabel = computed(() => {
  return `${
    invoiceDetailsForm.form.mvpFrequency === PaymentScheduleFrequency.MONTHLY ? 'Monthly' : 'Quarterly'
  } Amount`;
});

const dividedAmount = computed(() => {
  if (sellAmount.value && invoiceDetailsForm.form.mvpNumPayments) {
    return sellAmount.value / invoiceDetailsForm.form.mvpNumPayments;
  }
  return 0;
});
function loadNonTradeableDays() {
  services.pricingEngine
    .getNonTradeableDays(HedgingInstruments.VANILLA_OPTIONS, `${sellCurrency.value}${buyCurrency.value}`)
    .subscribe((result: NonTradeableDays) => {
      holidays.value = result.holidays.filter((d) => !result.weekend.includes(d));
      weekends.value = result.weekend;

      if (holidays.value) {
        setState(getChildModel(invoiceDetailsForm.form, 'mvpFirstPaymentDate')!, 'holidays', holidays.value);
      }
      if (weekends.value) {
        setState(getChildModel(invoiceDetailsForm.form, 'mvpFirstPaymentDate')!, 'weekends', weekends.value);
      }

      emit('update:disabledDates', [...holidays.value, ...weekends.value]);
    });
}

function loadNumberOfPayments(frequency: PaymentScheduleFrequency) {
  let options = [];
  if (frequency === PaymentScheduleFrequency.QUARTERLY) {
    for (let i = 1; i < 9; i++) {
      options.push({ value: i, label: i.toString() });
    }
  } else if (frequency === PaymentScheduleFrequency.MONTHLY) {
    for (let i = 1; i < 25; i++) {
      options.push({ value: i, label: i.toString() });
    }
  }

  if (!options.find((option) => option.value === invoiceDetailsForm.form.mvpNumPayments)) {
    invoiceDetailsForm.form.mvpNumPayments = null;
  }

  setState(getChildModel(invoiceDetailsForm.form!, 'mvpNumPayments')!, 'options', options);
}

watch(
  () => invoiceDetailsForm.form.mvpFrequency,
  () => {
    loadNumberOfPayments(invoiceDetailsForm.form.mvpFrequency);
  },
  { immediate: true }
);

function sellOptions(newVal?: string) {
  const currenciesPairs = currencies.value.filter((currency) =>
    currency.currencyPair.startsWith(newVal ?? buyCurrency.value)
  );

  sellCurrencies.value = currenciesPairs.reduce((acc, curr: CurrencyPair) => {
    if (!acc.includes(curr.currencyPair.slice(3))) {
      acc.push(curr.currencyPair.slice(3));
    }
    return acc;
  }, [] as string[]);

  setState(getChildModel(customerPaymentForm.form, 'sellCurrency')!, 'options', sellCurrencies.value);

  if (!props.model?.sellCurrency || (sellCurrency.value && sellCurrency.value === newVal)) {
    customerPaymentForm.form.sellCurrency = sellCurrencies.value.includes('EUR') ? 'EUR' : sellCurrencies.value[0];
  }
}

watch(buyCurrency, (newVal, oldVal) => {
  if (!oldVal) {
    sellOptions(newVal);
  } else if (oldVal && oldVal !== newVal) {
    sellOptions(newVal);
  }

  if (oldVal && oldVal !== newVal && sellCurrency.value) {
    loadNonTradeableDays();
    loadCutoffTime();
  }
});

watch(sellCurrency, (newVal, oldVal) => {
  if (oldVal && oldVal !== newVal && buyCurrency.value) {
    loadNonTradeableDays();
    loadCutoffTime();
  }
});

function loadCurrencies() {
  requestManager
    .currentOrNew(
      'loadCurrencies',
      tradeState.services.fxReference.listCurrenciesPairs(
        { optionsEnabled: true, pageSize: 100 },
        onBehalfOfClient.value?.id,
        {
          errors: { silent: true },
        }
      )
    )
    .subscribe((currenciesResponse) => {
      currencies.value = currenciesResponse.list.reduce((acc, curr: CurrencyPair) => {
        const ccy1 = curr.currencyPair.slice(0, 3);
        const ccy2 = curr.currencyPair.slice(3);
        const reverseCurrency = { ...curr, currencyPair: ccy2.concat(ccy1) };

        if (!currencies.value.includes(curr)) {
          acc.push(curr);
        }
        if (!currencies.value.includes(reverseCurrency)) {
          acc.push(reverseCurrency);
        }

        return acc;
      }, [] as CurrencyPair[]);

      buyCurrencies.value = currencies.value.reduce((acc, curr: CurrencyPair) => {
        if (!acc.includes(curr.currencyPair.slice(0, 3))) {
          acc.push(curr.currencyPair.slice(0, 3));
        }
        return acc;
      }, [] as string[]);

      setState(getChildModel(invoiceDetailsForm.form, 'buyCurrency')!, 'options', buyCurrencies.value);

      if (!props.model?.buyCurrency) {
        invoiceDetailsForm.form.buyCurrency = buyCurrencies.value.includes('GBP') ? 'GBP' : buyCurrencies.value[0];
      }

      sellOptions();

      loadNonTradeableDays();

      loadCutoffTime();
    });
}

loadCurrencies();

const settlementDate = computed(() => {
  let lastPaymentDate;

  if (
    invoiceDetailsForm.form.mvpFrequency === PaymentScheduleFrequency.MONTHLY &&
    invoiceDetailsForm.form.mvpNumPayments &&
    invoiceDetailsForm.form.mvpFirstPaymentDate
  ) {
    lastPaymentDate = addMonths(
      new Date(invoiceDetailsForm.form.mvpFirstPaymentDate),
      invoiceDetailsForm.form.mvpNumPayments - 1
    );
  } else if (
    invoiceDetailsForm.form.mvpFrequency === PaymentScheduleFrequency.QUARTERLY &&
    invoiceDetailsForm.form.mvpNumPayments &&
    invoiceDetailsForm.form.mvpFirstPaymentDate
  ) {
    lastPaymentDate = addQuarters(
      new Date(invoiceDetailsForm.form.mvpFirstPaymentDate),
      invoiceDetailsForm.form.mvpNumPayments - 1
    );
  }

  let disallowedDates = [...weekends.value, ...holidays.value];

  if (lastPaymentDate) {
    if (lastPaymentDate > calcMinForwardDate(disallowedDates)) {
      while (disallowedDates.includes(format(lastPaymentDate, 'dd-MM-yyyy'))) {
        lastPaymentDate = addDays(lastPaymentDate, 1);
      }

      return lastPaymentDate;
    }
  }

  return calcMinForwardDate([...weekends.value, ...holidays.value]);
});

const createTradeRequest = computed(() => {
  return {
    amountType: AmountType.BUY,
    amount: invoiceDetailsForm.form.buyAmount,
    tradeDirection: `${sellCurrency.value}${buyCurrency.value}`,
    clientId: clientId.value,
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    targetTimeFrame: TimeFrames.OTHER,
    targetDate: settlementDate.value.toISOString(),
  };
});

const vanillaRequest = computed(() => {
  const { buyAmount } = toDataModel(invoiceDetailsForm.form);

  return {
    clientId: clientId.value,
    amountType: AmountType.BUY,
    amount: buyAmount,
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    settlementDate:
      settlementDate.value && !isNaN(settlementDate.value.valueOf())
        ? format(new Date(settlementDate.value), 'yyyy-MM-dd')
        : '',
    strikeRate: strikeRate.value,
  };
});

function calcMinForwardDate(disallowedDates: string[]) {
  let date = startOfDay(new Date());

  for (let i = 0; i < 3; i++) {
    do {
      date = addDays(date, 1);
    } while (disallowedDates.includes(format(date, 'dd-MM-yyyy')));
  }

  return stripZoneOffset(startOfDay(date));
}

watch(
  [holidays, weekends],
  () => {
    minForwardDate.value = calcMinForwardDate([...holidays.value, ...weekends.value]);
  },
  { immediate: true }
);

function loadCutoffTime() {
  cutoffTime.value = undefined;

  if (clientId.value) {
    requestManager
      .sameOrCancelAndNew(
        'getCutoffTime',
        tradeState.services.pricingEngine.getCutoffTime(
          `${sellCurrency.value}${buyCurrency.value}`,
          clientId.value,
          FxOperation.FX_FORWARD,
          undefined,
          onBehalfOfClient.value?.id
        )
      )
      .subscribe((response) => {
        cutoffTime.value = cloneDeep(response);
      });
  }
}

function handleErrorsMessages(error: ApiError) {
  if (
    error.code === GenericErrorCodes.VALIDATION_ERROR &&
    error.subErrors?.length &&
    error.subErrors[0].code === PricingEngineErrorCodes.AMOUNT_OUT_OF_BOUNDS
  ) {
    setState(getChildModel(invoiceDetailsForm.form, 'buyAmount')!, 'errors', [
      {
        name: 'peError',
        error: error.subErrors[0].message,
      },
    ]);

    return;
  }

  if (error.code === GenericErrorCodes.VALIDATION_ERROR && error.subErrors?.length) {
    toast.info(error.subErrors[0].message);
    return;
  } else {
    toast.error('An unexpected problem has occurred. Please try again later.');
  }
}

function requestNewVanilla() {
  return requestManager
    .sameOrCancelAndNew(
      'createVanillaOptions',
      services.pricingEngine.createVanillaOptionsQuote(vanillaRequest.value, onBehalfOfClient.value?.id)
    )
    .subscribe(
      (price) => {
        if (price) {
          sellAmount.value =
            price.ccy1.currency === sellCurrency.value
              ? price.ccy1.clientAmount + price.ccy1.optionsCurrencyData.totalPremium
              : price.ccy2.clientAmount + price.ccy2.optionsCurrencyData.totalPremium;

          targetDate.value = addMinutes(new Date(), 1);

          vanillaSolution.value = cloneDeep(price);
        }
      },
      (e) => {
        targetDate.value = undefined;
        vanillaSolution.value = undefined;
        strikeRate.value = undefined;
        sellAmount.value = 0;

        handleErrorsMessages(e.response?.data);
      }
    );
}

function requestPrices() {
  strikeRate.value = undefined;

  if (createTradeRequest.value.clientId && cutoffTime.value) {
    requestManager
      .sameOrCancelAndNew(
        'reloadPrices',
        forkJoin([
          services.pricingEngine.createForwardQuote(createTradeRequest.value as ForwardQuotePriceRequest, {
            options: { errors: { silent: true } },
          }),
          services.pricingEngine.createSpotQuote({
            ...createTradeRequest.value,
            targetDate: cutoffTime.value.firstConversionDate,
          } as ForwardQuotePriceRequest),
        ])
      )
      .subscribe(
        ([forwardPrice, spotPrice]) => {
          const primaryFixedCurrency = forwardPrice.ccy1.isPrimaryRate ? forwardPrice.ccy1 : forwardPrice.ccy2;
          const primarySpotCurrency = spotPrice.ccy1.isPrimaryRate ? spotPrice.ccy1 : spotPrice.ccy2;

          strikeRate.value =
            Math.round(((primaryFixedCurrency.clientRate + primarySpotCurrency.clientRate) / 2) * 10000) / 10000;

          requestNewVanilla();
        },
        (e) => {
          targetDate.value = undefined;
          vanillaSolution.value = undefined;
          strikeRate.value = undefined;
          sellAmount.value = 0;

          handleErrorsMessages(e.response?.data);
        }
      );
  }
}

debouncedPrices.value = debounce(() => {
  setState(getChildModel(invoiceDetailsForm.form, 'buyAmount')!, 'errors', []);
  requestNewPrice();
}, 800);

function requestNewPrice() {
  if (isValid.value) {
    requestPrices();
  }
}

watch(createTradeRequest, () => {
  debouncedPrices.value!();
});

function reset() {
  invoiceDetailsForm.form.buyAmount = null;
  invoiceDetailsForm.form.mvpNumPayments = null;
  invoiceDetailsForm.form.mvpFrequency = PaymentScheduleFrequency.MONTHLY;
  invoiceDetailsForm.form.mvpFirstPaymentDate = null;
  strikeRate.value = undefined;
  sellAmount.value = 0;
  targetDate.value = undefined;
  paymentRequestForm.form.mvpCustomerEmail = null;
  paymentRequestForm.form.mvpInvoiceNumber = null;
  paymentRequestForm.form.mvpDescription = null;

  if (invoiceDetailsForm.validation) {
    resetForm(invoiceDetailsForm.validation);
  }
  if (customerPaymentForm.validation) {
    resetForm(customerPaymentForm.validation);
  }
  if (paymentRequestForm.validation) {
    resetForm(paymentRequestForm.validation);
  }
}

watch(
  () => requestManager.anyPending,
  () => {
    emit('update:isLoading', requestManager.anyPending);
  }
);

const calculatedModel = computed(() => {
  return {
    ...toDataModel(invoiceDetailsForm.form),
    ...toDataModel(customerPaymentForm.form),
    ...toDataModel(paymentRequestForm.form),
    strikeRate,
    sellAmount,
  };
});

watch(
  calculatedModel,
  () => {
    emit('update:model', calculatedModel.value);
  },
  { immediate: true }
);

watch(
  vanillaSolution,
  () => {
    if (vanillaSolution.value) {
      emit('update:solution', vanillaSolution.value);
    }
  },
  { immediate: true }
);

watch(
  isInvalid,
  () => {
    emit('update:isInvalid', isInvalid.value);
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    if (props.model) {
      updateModel(invoiceDetailsForm.form, props.model);
      updateModel(customerPaymentForm.form, props.model);
      updateModel(paymentRequestForm.form, props.model);

      sellAmount.value = props.model.sellAmount;
      strikeRate.value = props.model.strikeRate;
    }
  },
  { immediate: true }
);

defineExpose({ reset: reset });
</script>

<template>
  <div>
    <TileCard tileTitle="Invoice Limits">
      <ValidatedForm :fm="invoiceDetailsForm.form" :validation.sync="invoiceDetailsForm.validation" />
    </TileCard>
    <TileCard tileTitle="Customer Payment">
      <VRow>
        <VCol cols="12" lg="4">
          <ValidatedForm :fm="customerPaymentForm.form" :validation.sync="customerPaymentForm.validation" />
        </VCol>
        <VCol cols="6" lg="3" class="offset-lg-1 mb-2" alignSelf="end">
          <h3 class="mb-0">
            {{ dividedLabel }}
          </h3>
          <VRow alignV="end">
            <VCol>
              <span>
                {{ sellCurrency }}
                <LoadingIcon
                  v-if="
                    isValid &&
                    (requestManager.requestStates.getCutoffTime === 'pending' ||
                      requestManager.requestStates.reloadPrices === 'pending' ||
                      requestManager.requestStates.createVanillaOptions === 'pending')
                  " />
                <strong v-else
                  ><FormattedCurrency :value="dividedAmount" :mainFontSize="24" :decimalFontSize="16" /></strong
              ></span>
            </VCol>
          </VRow>
        </VCol>
        <VCol cols="6" lg="3" class="mb-2" alignSelf="end">
          <h3 class="mb-0">Total Amount</h3>
          <VRow alignV="end">
            <VCol>
              <span>
                {{ sellCurrency }}
                <LoadingIcon
                  v-if="
                    isValid &&
                    (requestManager.requestStates.getCutoffTime === 'pending' ||
                      requestManager.requestStates.reloadPrices === 'pending' ||
                      requestManager.requestStates.createVanillaOptions === 'pending')
                  " />
                <strong v-else
                  ><FormattedCurrency :value="sellAmount" :mainFontSize="24" :decimalFontSize="16" /></strong
              ></span>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="12" lg="3" class="mb-2 mb-lg-0">
          <DataRow cols="7" label="Conversion Rate">
            <LoadingIcon
              v-if="
                isValid &&
                (requestManager.requestStates.getCutoffTime === 'pending' ||
                  requestManager.requestStates.reloadPrices === 'pending')
              "
            />
            <span v-else>{{ formatCurrencyValue(strikeRate) ?? '-' }}</span></DataRow
          >
        </VCol>
        <VCol cols="7" v-if="targetDate">
          <UpdatingInTimer
            :target-date="targetDate"
            :loading="
              isValid &&
              (requestManager.requestStates.getCutoffTime === 'pending' ||
                requestManager.requestStates.reloadPrices === 'pending' ||
                requestManager.requestStates.createVanillaOptions === 'pending')
            "
            @timer-end="requestNewPrice"
          />
        </VCol>
      </VRow>
    </TileCard>
    <TileCard tileTitle="Payment Request Details">
      <ValidatedForm :fm="paymentRequestForm.form" :validation.sync="paymentRequestForm.validation" />
    </TileCard>
  </div>
</template>
