<script lang="ts" setup>
import { useAuthStore } from '@/app/store/authStore';
import { useToast } from 'ah-common-lib/src/toast/Toast';
import { VButton } from 'ah-common-lib/src/common/components';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import PlaidIntegrationsTestComponent from '@/app/components/integrations/PlaidIntegrationsTestComponent.vue';
import { useFeatureFlagStore } from 'ah-common-stores';
import { computed, reactive, ref } from 'vue';
import { FEFeatureFlags, feFeatureFlags } from 'config/baseConfig';
import { VCol } from 'ah-common-lib/src/common/components';
import FeatureFlagList from 'ah-common-stores/src/devTools/FeatureFlagList.vue';
import MonthSelectorInput from 'ah-common-lib/src/date/MonthSelectorInput.vue';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { dateField, monthSelectorField } from 'ah-common-lib/src/form/models';

const appVersion = window.VUE_APP_VERSION;

const commitHash = window.VUE_APP_COMMIT_HASH;

const authStore = useAuthStore();

const toast = useToast();

const requestManager = useRequestManager().manager;

const featureFlagStore = useFeatureFlagStore();

const mockDate = ref<Date>();

const dateForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'dateForm',
    title: 'Dates',
    fieldType: 'form',
    fields: [dateField('date', 'Normal Date'), monthSelectorField('month', 'Month Selection')],
  }),
  validation: null,
});

const featureFlagEdits = computed(() =>
  Object.keys(feFeatureFlags).map((key) => {
    return {
      key: key as keyof FEFeatureFlags,
      state: featureFlagStore.isFEFeatureFlagOn(key as keyof FEFeatureFlags),
      edited: featureFlagStore.feFeatureFlagOverrideArr.some((i) => i.key === (key as keyof FEFeatureFlags)),
    };
  })
);

function invalidateSession() {
  authStore.authToken = 'INVALID';
  toast.success('Session invalidated');
}

function invalidateRefreshToken() {
  authStore.refreshToken = 'INVALID';
  toast.success('Refresh token invalidated');
}

function refreshSession() {
  requestManager.sameOrNewPromise(
    'refreshSession',
    () =>
      authStore.refreshSession().then(() => {
        authStore.isLoggedIn ? toast.success('Session refreshed') : toast.error('Session in error, logged out user');
      }),
    authStore.authToken
  );
}
</script>
<template>
  <div class="padded-space" id="dev-settings-view">
    <div class="centered-space">
      <h2>Dev Settings</h2>
      <BoxGrid alignH="start">
        <BoxGridBlock cols="8" lg="8" md="6" sm="12" order-sm="1" order-md="0">
          <VButton class="mb-3 btn-stroked" @click="invalidateSession">Invalidate Session</VButton>
          <VButton class="mb-3 btn-stroked" @click="invalidateRefreshToken">Invalidate Refresh Token</VButton>
          <VButton
            class="mb-3"
            :loading="requestManager.requestStates.refreshSession === 'pending'"
            @click="refreshSession"
            >Refresh Session</VButton
          >
        </BoxGridBlock>
        <BoxGridBlock cols="4" lg="4" md="6" sm="12">
          <h3>App version</h3>
          <div class="versions">
            <DataRow label="Version" class="app-version">{{ appVersion }}</DataRow>
            <DataRow class="hash-row app-commit-hash" label="Commit Hash">{{ commitHash }}</DataRow>
          </div>
        </BoxGridBlock>
        <BoxGridBlock cols="12">
          <h3>Plaid Integrations</h3>
          <PlaidIntegrationsTestComponent include-revoked />
        </BoxGridBlock>
        <BoxGridBlock cols="12">
          <h3>FE Feature Flags</h3>
          <p>These are <b>only</b> flags defined exclusively at the UI source code level.</p>
          <VRow v-for="edit in featureFlagEdits" :key="edit.key">
            <VCol class="mb-2" cols="10">
              <span :class="{ 'font-weight-bold': edit.edited }">
                {{ edit.key }}
              </span>
              <template v-if="edit.edited">
                * edited <a class="link" @click="featureFlagStore.unsetFEFeatureFlag(edit.key)">reset</a>
              </template>
            </VCol>
            <VCol cols="2" class="text-right">
              <BFormCheckbox
                :checked="edit.state"
                name="check-button"
                switch
                @change="featureFlagStore.setFEFeatureFlag({ key: edit.key, state: $event })"
              />
            </VCol>
          </VRow>
        </BoxGridBlock>
        <BoxGridBlock cols="12">
          <h3>Split.IO Feature Flags</h3>
          <FeatureFlagList />
        </BoxGridBlock>
        <BoxGridBlock cols="12">
          <h3>Kitchen sink</h3>
          <MonthSelectorInput :value.sync="mockDate" />
          <ValidatedForm :fm="dateForm.form" :validation.sync="dateForm.validation" />
        </BoxGridBlock>
      </BoxGrid>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.versions {
  @include themedTextColor($color-text-secondary);

  .hash-row {
    word-break: break-word;
  }
}
</style>
