<script setup lang="ts">
import { PropType, reactive, computed, watch, onBeforeMount } from 'vue';
import { makeFormModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { selectField } from 'ah-common-lib/src/form/models';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { useTradeState } from '../..';
import { ValidatedForm } from 'ah-common-lib/src/form/components';

const props = defineProps({
  currency: {
    type: String,
    default: '',
  },
  allowEmpty: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: '',
  },
  allowedCurrencies: {
    type: Array as PropType<String[]>,
  },
  disallowedCurrencies: {
    type: Array as PropType<String[]>,
  },
});

const emit = defineEmits({
  'update:currency': (_currency: string) => true,
});

const tradeState = useTradeState();

const currencyFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'currencyForm',
    title: '',
    fieldType: 'form',
    fields: [selectField('currency', ``, [])],
  }),
  validation: null,
});

const currencyOptions = computed(() => {
  let out = tradeState.store
    .useSettingsStore()
    .currencies.filter((c) => c.enabled)
    .map((i) => ({
      value: i.currency,
      label: i.currency,
    }));

  if (props.allowedCurrencies) {
    out = out.filter((c) => props.allowedCurrencies!.includes((c as any).value));
  }

  if (props.disallowedCurrencies) {
    out = out.filter((c) => !props.disallowedCurrencies!.includes((c as any).value));
  }

  return out;
});

onBeforeMount(() => {
  setState(getChildModel(currencyFormDef.form, 'currency')!, 'title', props.label);

  tradeState.store
    .useSettingsStore()
    .loadTradeableCurrencies()
    .then(() => {
      onOptionsChange();
      onQueryChange();
    });
});

function onOptionsChange() {
  if (currencyFormDef.form) {
    const currencySelect = getChildModel(currencyFormDef.form, 'currency')!;

    setState(currencySelect, 'readonly', currencyOptions.value.length <= 1);

    checkCurrencyValidity();

    if (currencyOptions.value.length === 1 && currencyFormDef.form.currency !== currencyOptions.value[0].value) {
      currencyFormDef.form.currency = currencyOptions.value[0].value;
      onCurrencyChange();
    }
  }
}

watch(
  currencyOptions,
  () => {
    setState(getChildModel(currencyFormDef.form, 'currency')!, 'options', currencyOptions.value);

    onOptionsChange();
  },
  { immediate: true }
);

function onQueryChange() {
  if (currencyFormDef.form) {
    currencyFormDef.form.currency = props.currency;
    checkCurrencyValidity();
  }
}

watch(() => props.currency, onQueryChange);

function checkCurrencyValidity() {
  if (
    currencyFormDef.form &&
    currencyFormDef.form.currency &&
    currencyOptions.value.length &&
    currencyOptions.value.findIndex((o) => o.value === currencyFormDef.form!.currency) === -1
  ) {
    currencyFormDef.form.currency = props.allowEmpty ? '' : currencyOptions.value[0].value;
    onCurrencyChange();
  }
}

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    onCurrencyChange();
  }
}

function onCurrencyChange() {
  emit('update:currency', currencyFormDef.form!.currency);
}
</script>

<template>
  <ValidatedForm
    :fm="currencyFormDef.form"
    :makeValidation.sync="currencyFormDef.validation"
    @form-event="onFormEvent"
  />
</template>

<style lang="scss" scoped></style>
