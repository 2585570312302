import { render, staticRenderFns } from "./CurrencySelectInput.vue?vue&type=template&id=aa0b14b2&scoped=true"
import script from "./CurrencySelectInput.vue?vue&type=script&setup=true&lang=ts"
export * from "./CurrencySelectInput.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0b14b2",
  null
  
)

export default component.exports