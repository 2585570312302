<script setup lang="ts">
import { computed, PropType } from 'vue';
import InfoBlock from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { OnboardingIndividualInfo } from 'ah-api-gateways';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';

const props = defineProps({
  member: {
    type: Object as PropType<OnboardingIndividualInfo>,
    required: true,
  },
});

const userBlock = [
  { label: 'Title', key: 'title', cols: 4 },
  { label: 'First Name', key: 'firstName', cols: 4 },
  { label: 'Last Name', key: 'lastName', cols: 4 },
  { label: 'Email Address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 4 },
  { label: 'Date of Birth', key: 'birthDate', cols: 4 },
  { label: 'Requested Permissions', key: 'permissions', cols: 4 },
];

function addressHistoryBlock(isOwnerFromUK: boolean) {
  return [
    { label: 'Address Line 1', key: 'addressLine', cols: 4 },
    { label: 'City', key: 'city', cols: 4 },
    { label: 'State/Province/County', key: 'stateOrProvince', cols: 4 },
    { label: 'Postcode/Zip Code', key: 'postalCode', cols: 4 },
    { label: 'Country', key: 'countryCode', cols: 4 },
    ...(isOwnerFromUK
      ? [
          {
            label: 'When did the Signatory move to this address?',
            key: 'residingFrom',
            cols: 8,
          },
        ]
      : []),
  ];
}

const isMemberAUkResident = computed(() => props.member.currentAddress?.countryCode === 'GB');
</script>

<template>
  <div class="client-members-info-block" x-test-name="client-members-info-block">
    <InfoBlock emptyValue="-" :model="member" :info="userBlock">
      <template #permissions-value> Superuser </template>
      <template #birthDate-value>
        {{ formatDate(member.birthDate, 'dd MMM yyyy') }}
      </template>
    </InfoBlock>

    <template v-if="member.currentAddress">
      <h3>Current Address</h3>
      <InfoBlock :model="member.currentAddress" :info="addressHistoryBlock(isMemberAUkResident)">
        <template #countryCode-value>
          {{ countryNameFromCC(member.currentAddress?.countryCode) }}
        </template>
        <template #residingFrom-value>
          {{ formatDate(member.currentAddress?.residingFrom, 'MMM, yyyy') }}
        </template>
      </InfoBlock>
    </template>
    <template v-if="member.previousAddresses && member.previousAddresses?.length > 0">
      <h3>Previous {{ member.previousAddresses.length > 1 ? 'Addresses' : 'Address' }}</h3>
      <div v-for="(previousAddress, index) in member.previousAddresses" :key="`secondaryOwner-${index}`">
        <h4 class="mb-3">Previous Address N&deg;{{ index + 1 }}</h4>
        <InfoBlock :model="previousAddress" :info="addressHistoryBlock(isMemberAUkResident)">
          <template #countryCode-value>
            {{ countryNameFromCC(previousAddress.countryCode) }}
          </template>
          <template #residingFrom-value>
            {{
              `${formatDate(previousAddress.residingFrom, 'MMM, yyyy')} - ${formatDate(
                previousAddress.residingTo,
                'MMM, yyyy'
              )}`
            }}
          </template>
        </InfoBlock>
      </div>
      <hr />
    </template>
  </div>
</template>
