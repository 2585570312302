<script lang="ts" setup>
import { BankAccount, Beneficiary, BankingScheme, BeneficiaryType } from 'ah-api-gateways';
import { makeFormModel } from 'ah-common-lib/src/form/helpers/formMaker';
import {
  setState,
  getChildModel,
  toDataModel,
  updateModel,
  getState,
} from 'ah-common-lib/src/form/helpers/formHelpers';
import { bankAccountDetailsFormSchema } from 'ah-common-lib/src/form/formModels';
import { SEPACountries } from '../../helpers/bankAccount';
import { LocalizedBeneficiaryFieldData, PurposeCodeResponse } from 'ah-api-gateways';
import { ibanCountryGroupings } from 'ah-common-lib/src/constants/ibanCountryGroupings ';
import { countries } from 'ah-common-lib';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useBeneficiariesState } from 'ah-beneficiaries';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { cloneDeep, isEqual } from 'lodash';

const requestManager = useRequestManager({
  exposeToParent: ['loadCountries', 'loadCurrencyBankAccountFields'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadCountries') {
      loadCountries();
    }
    if (k === 'loadCurrencyBankAccountFields') {
      loadFormFields();
    }
  },
});

const beneficiarieState = useBeneficiariesState();

const props = defineProps<{
  model: Partial<BankAccount>;
  beneficiary?: Partial<Beneficiary>;
}>();

const bankAccountDetailsFM = reactive<FormDefinition>({
  form: makeFormModel(bankAccountDetailsFormSchema(false)),
  validation: null,
});

const emit = defineEmits<{
  (e: 'update:loading', value: boolean | null): void;
  (e: 'update:model', value: Partial<BankAccount> | null): void;
}>();

function loadCountries() {
  requestManager.manager.newPromise(
    'loadCountries',
    beneficiarieState.store
      .useSettingsStore()
      .loadCountries()
      .then(() => {
        setBankCountryCodeOptions();
      })
  );
}

loadCountries();

watch(
  () => props.model,
  () => {
    if (props.model) {
      const model = cloneDeep(props.model) as any;
      const purposeCodeText = getChildModel(bankAccountDetailsFM.form, 'purposeCodeText');
      const purposeCodeSelect = getChildModel(bankAccountDetailsFM.form, 'purposeCodeSelect');
      if (model.purposeCode && purposeCodeText && purposeCodeSelect) {
        if (!getState(purposeCodeText, 'hidden')) {
          model.purposeCodeText = model.purposeCode;
        } else if (!getState(purposeCodeSelect, 'hidden')) {
          model.purposeCodeSelect = model.purposeCode;
        }
      }
      updateModel(bankAccountDetailsFM.form, model);
    }
  },
  { immediate: true }
);

function onFormEvent() {
  clearErrorMessages();
  emitAnyModelChanges(getDataModel());
}

function getDataModel() {
  const changes = toDataModel(bankAccountDetailsFM.form);
  const purposeCodeText = getChildModel(bankAccountDetailsFM.form, 'purposeCodeText');
  const purposeCodeSelect = getChildModel(bankAccountDetailsFM.form, 'purposeCodeSelect');
  if (purposeCodeText && purposeCodeSelect) {
    delete changes.purposeCodeText;
    delete changes.purposeCodeSelect;
    if (!getState(purposeCodeText, 'hidden')) {
      changes.purposeCode = bankAccountDetailsFM.form.purposeCodeText;
    } else if (!getState(purposeCodeSelect, 'hidden')) {
      changes.purposeCode = bankAccountDetailsFM.form.purposeCodeSelect;
    } else {
      delete changes.purposeCode;
    }
    return changes;
  }
}

function emitAnyModelChanges(changes: Partial<BankAccount>) {
  const model = { ...props.model, ...changes };

  if (!isEqual(props.model, model)) {
    emit('update:model', model);
  }
}

watch(
  () => bankAccountDetailsFM.form.iban,
  () => {
    let field = getChildModel(bankAccountDetailsFM.form, 'iban');

    if (field) {
      setState(field, 'errors', []);
    }
  },
  { immediate: true }
);

function setErrorMessage(fieldName: string, message: string) {
  let field = getChildModel(bankAccountDetailsFM.form, fieldName);

  if (field) {
    setState(field, 'errors', [
      {
        name: 'customErrorMessage',
        error: message,
      },
    ]);
  }
}

function clearErrorMessages() {
  setState(bankAccountDetailsFM.form, 'errors', [], true);
}

const beneficiaryType = computed(() => {
  return props.beneficiary?.type || BeneficiaryType.INDIVIDUAL;
});

/**
 * Bank country for field validation purposes
 *
 * Will default to the selected bank country, if any. Otherwise it will be the first available option
 */
const fieldBankCountry = computed(() => {
  if (props.model.bankCountry) {
    return props.model.bankCountry;
  }
  if (bankCountryOptions.value.length > 0) {
    return bankCountryOptions.value[0].value;
  }

  return null;
});

function loadFormFields() {
  if (props.model.currency && props.model.bankingScheme && fieldBankCountry.value) {
    const params = {
      currency: props.model.currency!,
      bankingScheme: props.model.bankingScheme!,
      beneficiaryType: beneficiaryType.value,
      bankCountry: fieldBankCountry.value!,
    };
    return requestManager.manager.sameOrNewPromise(
      'loadCurrencyBankAccountFields',
      () =>
        Promise.all([
          beneficiarieState.store.useSettingsStore().loadCurrencyBankAccountFields({ params }),
          fieldBankCountry.value
            ? beneficiarieState.store.useSettingsStore().loadBankPurposeCodes({
                params: {
                  currency: params.currency!,
                  bankAccountCountry: params.bankCountry,
                  beneficiaryType: params.beneficiaryType,
                  bankingScheme: props.model.bankingScheme!,
                },
              })
            : null,
        ]),
      params
    );
  }
  return Promise.reject('Model is incomplete, cannot load form fields');
}

watch(
  () => requestManager.manager.anyPending,
  () => {
    emit('update:loading', requestManager.manager.anyPending);

    setState(bankAccountDetailsFM.form, 'readonly', requestManager.manager.anyPending);
  },
  { immediate: true }
);

const bankCountryOptions = computed(() => {
  return beneficiarieState.store
    .useSettingsStore()
    .activeCountries.filter((i) => {
      if (props.model.bankingScheme !== BankingScheme.LOCAL) {
        return true;
      }
      if (props.model.currency === 'EUR') {
        return SEPACountries.includes(i.cc);
      }
      return props.model.currency === i.currency;
    })
    .map((i) => ({ label: i.name, value: i.cc }));
});

watch(
  () => [props.model.currency, fieldBankCountry.value, beneficiaryType.value, props.model.bankingScheme],
  (newValue, oldValue) => {
    /* if any of the 4 key fields changes we need to remake
     * the form to display the correct and updated fields.
     * Example: China bank accounts don't have an IBAN so that field need to be hidden and cleared
     */
    if (isEqual(newValue, oldValue)) {
      return;
    }
    if (props.model.currency && props.model.bankingScheme && fieldBankCountry.value) {
      const currency = props.model.currency;
      const bankingScheme = props.model.bankingScheme;
      const beneficiaryTypeTemp = beneficiaryType.value;
      const bankCountry = fieldBankCountry.value;
      loadFormFields().then(([fieldDefinitions, purposeCodes]) => {
        if (
          currency !== props.model.currency ||
          bankingScheme !== props.model.bankingScheme ||
          beneficiaryTypeTemp !== beneficiaryType.value ||
          bankCountry !== fieldBankCountry.value
        ) {
          return;
        }
        setPurposeCodeFields(purposeCodes);
        setBankCountryCodeOptions();
        setFieldsFromDefinitions(fieldDefinitions);
      });
    } else {
      setBankCountryCodeOptions();
    }
  },
  { immediate: true }
);

function setFieldsFromDefinitions(fieldDefinitions: LocalizedBeneficiaryFieldData[]) {
  bankAccountDetailsFM.form.$fields.forEach((field) => {
    const definition = fieldDefinitions.find(
      (f) => f.fieldName === field.$name && f.beneficiaryType === beneficiaryType.value
    );
    if (definition) {
      setState(field, 'hidden', !definition.visible);

      if (!definition.visible) {
        bankAccountDetailsFM.form[field.$name] = null;
      }
      setState(field, 'required', definition.visible && definition.mandatory);
      if (definition.title) {
        setState(field, 'title', definition.title);
      }

      // EntityType is currently an exception: handled by loadEntityTypes
      if (definition.fieldName !== 'entityType' && definition.options?.length) {
        // If there is only one option, set value and hide field
        setState(field, 'options', definition.options);

        if (definition.options.length === 1) {
          bankAccountDetailsFM.form[field.$name] = definition.options[0].value;
          setState(field, 'hidden', true);
        } else if (!definition.options.find((o) => o.value === bankAccountDetailsFM.form[field.$name])) {
          bankAccountDetailsFM.form[field.$name] = definition.options[0].value;
        }
      } else {
        if (!definition.visible) {
          bankAccountDetailsFM.form[field.$name] = '';
        } else {
          bankAccountDetailsFM.form[field.$name] =
            bankAccountDetailsFM.form[field.$name] ||
            (props.beneficiary && (props.beneficiary as any)[field.$name]) ||
            '';
        }
      }

      emitAnyModelChanges(getDataModel());
    }
  });
}

function setPurposeCodeFields(purposeCodesResponse: PurposeCodeResponse | null) {
  const purposeCodeText = getChildModel(bankAccountDetailsFM.form, 'purposeCodeText');
  const purposeCodeSelect = getChildModel(bankAccountDetailsFM.form, 'purposeCodeSelect');

  if (!purposeCodeText || !purposeCodeSelect) {
    return;
  }

  if (!purposeCodesResponse?.mandatory) {
    // if purpose codes are not mandatory we are hiding it and setting them to empty forcefully
    setState(purposeCodeText, 'required', false);
    setState(purposeCodeSelect, 'required', false);
    setState(purposeCodeSelect, 'hidden', true);
    setState(purposeCodeText, 'hidden', true);
    bankAccountDetailsFM.form.purposeCodeText = '';
    bankAccountDetailsFM.form.purposeCodeSelect = null;
  } else {
    const emptyList = purposeCodesResponse.purposeCodes.length === 0;
    const multipleEntries = purposeCodesResponse.purposeCodes.length > 1;
    setState(purposeCodeText, 'required', !multipleEntries);
    setState(purposeCodeSelect, 'required', multipleEntries);
    setState(purposeCodeSelect, 'hidden', !multipleEntries);
    setState(purposeCodeText, 'hidden', !emptyList);

    if (multipleEntries) {
      // if there are multiple options we show the select and set it to the value of the model if that value
      // is within the available options
      const pCode = purposeCodesResponse.purposeCodes.find((p) => p.purposeCode === props.model.purposeCode);
      bankAccountDetailsFM.form.purposeCodeSelect = pCode ? props.model.purposeCode : '';

      setState(
        purposeCodeSelect,
        'options',
        purposeCodesResponse.purposeCodes.map((i) => ({
          label: i.purposeDescription,
          value: i.purposeCode,
        }))
      );
    } else if (!emptyList) {
      // if there's only one possible purpose code field will be hidden and we set the value to the
      // only available option
      bankAccountDetailsFM.form.purposeCodeText = purposeCodesResponse.purposeCodes[0].purposeCode;
    } else {
      // if there are no options the text field is shown and we populate it with the model's purpose code value
      bankAccountDetailsFM.form.purposeCodeText = props.model.purposeCode;
    }
  }

  emitAnyModelChanges(getDataModel());
}

function setBankCountryCodeOptions() {
  const countryCodeField = getChildModel(bankAccountDetailsFM.form, 'bankCountry');
  if (countryCodeField) {
    setState(countryCodeField, 'options', bankCountryOptions.value);

    if (
      bankCountryOptions.value.length === 1 &&
      bankAccountDetailsFM.form.bankCountry !== bankCountryOptions.value[0].value
    ) {
      bankAccountDetailsFM.form.bankCountry = bankCountryOptions.value[0].value;
    } else if (
      bankAccountDetailsFM.form.bankCountry &&
      !bankCountryOptions.value.find((o) => o.value === bankAccountDetailsFM.form.bankCountry)
    ) {
      bankAccountDetailsFM.form.bankCountry = '';
    }
  }
}

const countryCode = computed(() => {
  return countries.find((c) => c.value === props.model.bankCountry)
    ? countries.find((c) => c.value === props.model.bankCountry)?.label
    : props.model.bankCountry;
});

watch(
  () => [props.model.bankCountry],
  () => {
    let ibanCountries: string[] = [];

    if (props.model.bankCountry) {
      ibanCountries = [props.model.bankCountry];
    }

    if (ibanCountryGroupings.find((i: any) => i.cc === props.model.bankCountry)) {
      ibanCountries.push(ibanCountryGroupings.find((i: any) => i.cc === props.model.bankCountry)!.ibanCC);

      setState(getChildModel(bankAccountDetailsFM.form, 'iban')!, 'errorMessages', {
        ibanCC: `Only accounts from ${countryCode.value} are allowed`,
      });
    } else {
      setState(getChildModel(bankAccountDetailsFM.form, 'iban')!, 'errorMessages', undefined);
    }

    setState(getChildModel(bankAccountDetailsFM.form, 'iban')!, 'ibanAllowedCountries', ibanCountries);
  },
  { immediate: true }
);

defineExpose({ clearErrorMessages, setErrorMessage });
</script>

<template>
  <ValidatedForm :fm="bankAccountDetailsFM.form" v-bind="$attrs" v-on="$listeners" @form-event="onFormEvent" />
</template>
