<script lang="ts" setup>
import { BeneficiaryType, Beneficiary, BankingScheme } from 'ah-api-gateways';
import {
  makeFormModel,
  toDataModel,
  updateModel,
  getChildModel,
  setState,
  getState,
} from 'ah-common-lib/src/form/helpers';
import { individualDetailsForm, companyDetailsForm } from './formSchemas/beneficiaryFormSchemas';
import { computed, onBeforeMount, reactive, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useBeneficiariesState } from 'ah-beneficiaries';
import { FormModel } from 'ah-common-lib/src/form/interfaces/form';
import { isEqual } from 'lodash';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const emit = defineEmits<{
  (e: 'update:model', value: any): void;
  (e: 'update:loading', value: boolean): void;
}>();

const props = withDefaults(
  defineProps<{
    model?: Partial<Beneficiary>;
    isIndividual?: boolean | string;
    allowNameEdit: boolean | string;

    /**
     * Which banking scheme to load fields for. If allSchemesValidFields is truthy, this prop is ignored
     */
    bankingScheme: BankingScheme;
    /**
     * Whether to show all valid fields for both schemes
     *
     * If set as truthy, bankingScheme is ignored
     */
    allSchemesValidFields?: boolean | string;
    currency?: string;
    bankCountry: string;
  }>(),
  {
    allowNameEdit: false,
    /**
     * In order to get the correct beneficiary details (reference to PT-1958)
     * We set SWIFT as the default banking scheme because LOCAL will get us incorrect beneficiary detail fields
     */
    bankingScheme: BankingScheme.SWIFT,
    allSchemesValidFields: false,
  }
);

const beneficiarieState = useBeneficiariesState();

const onBehalfOfClient = useOnBehalfOf();

const beneficiaryDetailsForm: { form: FormModel } = reactive({
  form: makeFormModel(individualDetailsForm()),
});

const requestManager = useRequestManager({
  exposeToParent: ['loadBeneficiaryFields'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadBeneficiaryFields') {
      loadFormFields();
    }
  },
});

onBeforeMount(() => {
  beneficiarieState.store.useSettingsStore().loadEntityTypes();
});

const isIndividualBeneficiary = computed(() => {
  if (props.isIndividual !== null) {
    return !!props.isIndividual;
  }
  if (props.model && props.model.type) {
    return props.model.type === BeneficiaryType.INDIVIDUAL;
  }
  return false;
});

const beneficiaryType = computed(() => {
  return isIndividualBeneficiary.value ? BeneficiaryType.INDIVIDUAL : BeneficiaryType.COMPANY;
});

function onCountryCodeChange() {
  if (props.currency) {
    const currency = props.currency;
    loadFormFields().then((fieldDefinitions) => {
      if (currency !== props.currency) {
        return;
      }
      beneficiaryDetailsForm.form.$fields.forEach((field) => {
        const definition = fieldDefinitions.find(
          (f) => f.fieldName === field.$name && f.beneficiaryType === beneficiaryType.value
        );
        if (definition) {
          setState(field, 'hidden', !definition.visible);
          if (!definition.visible) {
            beneficiaryDetailsForm.form[field.$name] = null;
          }
          setState(field, 'required', definition.visible && definition.mandatory);
          if (definition.title) {
            setState(field, 'title', definition.title);
          }

          if (definition.options?.length || definition.fieldName === 'entityType') {
            setState(field, 'clearable', !definition.mandatory);
            // EntityType is currently an exception: handled by loadEntityTypes
            if (definition.options?.length && definition.fieldName !== 'entityType') {
              // If there is only one option, set value and hide field
              setState(field, 'options', definition.options);
              if (!definition.options.find((o) => o.value === beneficiaryDetailsForm.form[field.$name])) {
                beneficiaryDetailsForm.form[field.$name] = null;
              }
              if (definition.options.length === 1) {
                beneficiaryDetailsForm.form[field.$name] = definition.options[0].value;
                setState(field, 'hidden', true);
              }
            }
          }
        }
      });

      // Small hack for known field identificationType: if it has only one option we set the title of identificationValue to that option
      const identificationTypeDef = fieldDefinitions.find((f) => f.fieldName === 'identificationType');
      const identificationValueField = getChildModel(beneficiaryDetailsForm.form, 'identificationValue');
      if (identificationTypeDef?.options?.length === 1 && identificationValueField) {
        setState(identificationValueField, 'title', identificationTypeDef.options[0].label);
      }

      onFormEvent();
    });
  }
}

function onModelChange() {
  if (props.model) {
    updateModel(beneficiaryDetailsForm.form, props.model);
  }
}

function onFormEvent() {
  clearErrorMessages();
  emitAnyModelChanges();
}

function emitAnyModelChanges(changes: Partial<Beneficiary> = getFormModel(true)) {
  if (!isEqual(props.model, changes)) {
    emit('update:model', changes);
  }
}

watch(
  () => [isIndividualBeneficiary.value, props.allowNameEdit],
  () => {
    beneficiaryDetailsForm.form = isIndividualBeneficiary.value
      ? makeFormModel(individualDetailsForm(props.allowNameEdit !== false))
      : makeFormModel(companyDetailsForm(props.allowNameEdit !== false));

    if (!isIndividualBeneficiary.value) {
      const entityType = getChildModel(beneficiaryDetailsForm.form, 'entityType');
      if (entityType) {
        setState(entityType, 'options', []);
        beneficiarieState.store
          .useSettingsStore()
          .loadEntityTypes()
          .then((types) => {
            setState(
              entityType,
              'options',
              types.map((t) => ({
                label: t.legalForm,
                value: t.id,
              }))
            );
          });
      }
    }

    onCountryCodeChange();
    onModelChange();
    onFormEvent();
  },
  { immediate: true }
);

watch(() => props.model, onModelChange, { immediate: true });

function loadFormFields() {
  if (props.currency) {
    const params = {
      currency: props.currency!,
      beneficiaryType: beneficiaryType.value,
      bankingScheme: props.bankingScheme,
      bankCountry: props.bankCountry,
    };
    return requestManager.manager.sameOrNewPromise(
      'loadBeneficiaryFields',
      () =>
        props.allSchemesValidFields === false
          ? beneficiarieState.store.useSettingsStore().loadCurrencyBankAccountFields({ params })
          : Promise.all([
              beneficiarieState.store.useSettingsStore().loadCurrencyBankAccountFields({
                params: { ...params, bankingScheme: BankingScheme.LOCAL },
              }),
              beneficiarieState.store.useSettingsStore().loadCurrencyBankAccountFields({
                params: { ...params, bankingScheme: BankingScheme.SWIFT },
              }),
            ]).then((defArray) => {
              return defArray[0].map((definition) => {
                const swiftDefinition = defArray[1].find((d) => d.fieldName === definition.fieldName);
                if (swiftDefinition) {
                  definition.mandatory = definition.mandatory && swiftDefinition.mandatory;
                  definition.visible = definition.visible || swiftDefinition.visible;
                }
                return definition;
              });
            }),
      params
    );
  }
  return Promise.reject();
}

watch(() => [props.currency, props.isIndividual, props.bankingScheme, props.bankingScheme], onCountryCodeChange, {
  immediate: true,
});

function setErrorMessage(fieldName: string, message: string) {
  let field = getChildModel(beneficiaryDetailsForm.form, fieldName);

  if (field) {
    setState(field, 'errors', [
      {
        name: 'customError',
        error: message,
      },
    ]);
  }
}

function clearErrorMessages() {
  setState(beneficiaryDetailsForm.form, 'errors', [], true);
}

watch(
  () => requestManager.manager.anyPending,
  () => {
    emit('update:loading', requestManager.manager.anyPending);

    setState(beneficiaryDetailsForm.form, 'readonly', requestManager.manager.anyPending);
  },
  { immediate: true }
);

/**
 * Get the updated model from the form
 *
 * Can be passed an optional parameter - if truthy, will return the full model (rather than just an update)
 */
function getFormModel(useOriginal = false) {
  const out = {
    clientId: onBehalfOfClient.value?.id ?? beneficiarieState.store.useAuthStore().loggedInIdentity?.client?.id,
    ...(useOriginal ? props.model : {}),
    ...toDataModel(beneficiaryDetailsForm.form),
    type: beneficiaryType.value,
  };

  Object.keys(out).forEach((k) => {
    const field = getChildModel(beneficiaryDetailsForm.form, k);
    if (field && !beneficiaryDetailsForm.form[k] && getState(field, 'hidden')) {
      out[k] = null;
    }
  });

  if (isIndividualBeneficiary.value) {
    delete out.name;
    delete out.entityType;
  } else {
    delete out.firstName;
    delete out.lastName;
  }

  return out;
}

defineExpose({ setErrorMessage, clearErrorMessages });
</script>

<template>
  <div class="beneficiary-details-form">
    <ValidatedForm :fm="beneficiaryDetailsForm.form" v-on="$listeners" @form-event="onFormEvent" />
  </div>
</template>
