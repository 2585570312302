<script setup lang="ts">
import { findComponentBasePath } from 'ah-common-lib/src/helpers/route';
import { getCurrentInstance } from 'vue';

const props = defineProps({ path: { type: String, required: false, default: '' } });

// basePath is set on component setup and not expected to react to prop changes
// eslint-disable-next-line vue/no-setup-props-destructure
let basePath = props.path;
const instance = getCurrentInstance();
if (basePath === '' && instance != null) {
  basePath = findComponentBasePath(instance.proxy);
}
</script>

<template>
  <div class="main" id="trades-sidebar-view">
    <h2 class="px-4">Subscriptions</h2>
    <h6 class="menu-header">Subscription Management</h6>
    <ul>
      <RouterLink tag="li" class="menu-link" exact :to="`${basePath}/new-payment-schedule`">
        New Payment Schedule
      </RouterLink>
      <div class="menu-link-disabled"><span>My Drafts</span></div>
      <div class="menu-link-disabled"><span>Payment Schedules</span></div>
    </ul>
    <h6 class="menu-header">Customer Management</h6>
    <ul>
      <div class="menu-link-disabled"><span>All Customers</span></div>
      <div class="menu-link-disabled"><span>Add New Customer</span></div>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.menu-link-disabled {
  width: 100%;
  padding: 0.2em 1em;
  margin-bottom: 0.2em;
  font-size: 1.1em;
  line-height: 1.2em;
}
</style>
