import { render, staticRenderFns } from "./RemoveButton.vue?vue&type=template&id=04749d7a&scoped=true"
import script from "./RemoveButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./RemoveButton.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./RemoveButton.vue?vue&type=style&index=0&id=04749d7a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04749d7a",
  null
  
)

export default component.exports