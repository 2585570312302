<template>
  <AppBanner v-if="isClientInOnboarding" class="danger text-center">
    <template v-if="areTermsAndConditionsRequired">
      <p>
        <b>Acceptance of Updated T&Cs outstanding</b><br />
        <span v-if="isUserSignatory">
          In order to proceed, please review the
          <RouterLink to="/terms-and-conditions" class="plain-color-link">terms and conditions</RouterLink> of business,
          and authorise in your capacity as a duly authorised person.
        </span>
        <span v-else>
          In order to proceed,
          {{ clientOwner ? `${clientOwner.firstName} ${clientOwner.lastName}` : 'the authorised signarory' }} must
          review and authorise the updated terms and conditions of business.
        </span>
      </p>
    </template>
    <template v-if="areTermsAndConditionsSuspended">
      <p>
        <b>Account Suspended due to Updated T&C’s outstanding</b><br />
        <span v-if="isUserSignatory">
          Your account is currently suspended, in order to proceed review
          <RouterLink to="/terms-and-conditions" class="plain-color-link">terms and conditions</RouterLink> of business.
        </span>
        <span v-else>
          In order to proceed,
          {{ clientOwner ? `${clientOwner.firstName} ${clientOwner.lastName}` : 'the authorized signatory' }} must
          review and authorise the updated terms and conditions of business.
        </span>
      </p>
    </template>
    <p v-else class="mb-0">Your account is pending approval. Transactional features are currently disabled.</p>
    <template v-if="documentsNeedUploading || uboDocumentsNeedUploading">
      <p class="mb-0">
        {{ clientMessage }}
      </p>
    </template>
  </AppBanner>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import {
  onboardingStatuses,
  ClientFileCategories,
  ComplianceFilesStatus,
  ComplianceStatus,
  Individual,
} from 'ah-api-gateways';
import AppBanner from '@/app/components/common/AppBanner.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { MINUTE } from 'ah-common-lib/src/constants/time';

const individualClientDocs = [
  ClientFileCategories.PROOF_OF_ADDRESS,
  ClientFileCategories.PHOTO_ID,
  ClientFileCategories.PROOF_OF_FUNDS,
];

const companyDocs = [
  ClientFileCategories.INCORPORATED_DOCUMENT,
  ClientFileCategories.SAMPLE_INVOICE,
  ClientFileCategories.FINANCIAL_STATEMENT,
];

const companyClientDocs = [...companyDocs, ClientFileCategories.PHOTO_ID, ClientFileCategories.PROOF_OF_ADDRESS];

@Component({
  components: {
    AppBanner,
  },
})
export default class OnboardingStatusBanner extends Mixins(WithRequestManager) {
  requestManagerConfig = {
    exposeToParent: false,
  };

  private clientOwner: Individual | null = null;

  private loadComplianceInterval: number = -1;

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  created() {
    this.authStore.loadComplianceCase();
    this.loadComplianceInterval = window.setInterval(() => {
      if (this.authStore.isLoggedIn) {
        this.authStore.loadComplianceCase({ force: true });
      }
    }, MINUTE * 15);
  }

  beforeDestroy() {
    window.clearInterval(this.loadComplianceInterval);
  }

  @Watch('loggedIn', { immediate: true })
  onLoggedInChange() {
    if (this.loggedIn && this.authStore.isClientUser && this.isClientInOnboarding) {
      this.requestManager
        .newPromise(
          'loadDeps',
          Promise.all([
            this.$services.individual
              .listIndividuals({
                clientId: this.authStore.userData?.individual?.client?.id,
                isOwner: true,
              })
              .toPromise(),
            this.individualSettingsStore.loadClient(true),
            this.individualSettingsStore.loadClientDocuments(true),
            this.individualSettingsStore.loadClientUbos(true),
            this.authStore.loadComplianceCase(),
          ])
        )
        .then((responses) => {
          !this.clientWithUbos ? companyDocs.push(ClientFileCategories.RESOLUTION_LETTER) : '';
          if (responses[0].list.length === 1) {
            this.clientOwner = responses[0].list[0];
          }
        });
    }
  }

  get loggedIn() {
    return this.authStore.isLoggedIn;
  }

  get documentsNeedUploading() {
    if (this.requestManager.anyPending) {
      return false;
    }
    if (this.authStore.cachedComplianceCase.item) {
      if (this.authStore.cachedComplianceCase.item.documentUploadStatus === ComplianceFilesStatus.UPLOADED) {
        return false;
      }
    }
    const docList = this.isIndividualClient ? individualClientDocs : companyClientDocs;

    return docList.filter((i) => !this.individualSettingsStore.getClientDocument(i)).length > 0;
  }

  get uboDocumentsNeedUploading() {
    if (this.requestManager.anyPending || !this.ubos) {
      return false;
    }
    return !!this.ubos.find((ubo) => ubo.documentUploadStatus !== ComplianceFilesStatus.UPLOADED);
  }

  get isUserSignatory() {
    return this.isIndividualClient || this.authStore.userData?.individual?.owner;
  }

  get isIndividualClient() {
    return this.authStore.isClientUser && !this.authStore.isCompanyClient;
  }

  get areTermsAndConditionsRequired() {
    return this.authStore.complianceStatus === ComplianceStatus.TERMS_AND_CONDITIONS;
  }

  get areTermsAndConditionsSuspended() {
    return this.authStore.complianceStatus === ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED;
  }

  get isClientInOnboarding() {
    return this.authStore.complianceStatus && onboardingStatuses.includes(this.authStore.complianceStatus);
  }

  get ubos() {
    return this.individualSettingsStore.ubos;
  }

  get clientWithUbos() {
    return this.ubos && this.ubos.length > 0;
  }

  get clientMessage() {
    return `Your application has been submitted. In line with our regulatory obligation, our team may be in touch with you to request that you supply additional documents in support of your application. Simply log back into your account, navigate to the 'Settings' section and update your documents accordingly.`;
  }

  private get companyClientRedirect() {
    const docList = companyDocs.filter((i) => !this.individualSettingsStore.getClientDocument(i)).length > 0;
    return docList ? '/settings/client-information' : '/settings/personal-settings';
  }
}
</script>
