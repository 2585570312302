<script setup lang="ts">
import { computed, ref } from 'vue';
import PaymentScheduleForm from './PaymentScheduleForm.vue';
import PaymentScheduleReview from './PaymentScheduleReview.vue';
import { CreateTradeRequest, VanillaPriceResponse } from 'ah-api-gateways';
import { PaymentFormDetails } from '@/app/models/payments';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useToast } from 'ah-common-lib/src/toast';
import { mergeMap } from 'rxjs/operators';
import { waitForEntityCreation } from 'ah-requests';
import { formatDate } from 'ah-common-lib/src/helpers/time';

const props = defineProps({
  flowTitle: {
    type: String,
    default: 'Create Payment Schedule',
  },
});

enum PaymentScheduleCreatorSteps {
  PAYMENT_SCHEDULE_CREATOR = 'paymentScheduleCreator',
  PAYMENT_SCHEDULE_REVIEW = 'paymentScheduleReview',
}

const paymentScheduleForm = ref<InstanceType<typeof PaymentScheduleForm>>();

const requestManager = useRequestManager().manager;

const services = getServices();

const toast = useToast();

const onBehalfOfClient = useOnBehalfOf();

const stage = ref<PaymentScheduleCreatorSteps>(PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR);

const title = computed(() => {
  return stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR
    ? props.flowTitle
    : 'Review Payment Details & Schedule';
});

const solution = ref<VanillaPriceResponse>();

const model = ref<PaymentFormDetails>();

const isLoading = ref<boolean>(false);

const isInvalid = ref<boolean>(true);

const disabledDates = ref<string[]>();

const secondaryButton = computed(() => {
  return stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR ? 'Cancel' : 'Go Back';
});

const primaryButton = computed(() => {
  return stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR
    ? 'Review Payment Schedule'
    : 'Book Payment Schedule';
});

function backStep() {
  if (stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR) {
    paymentScheduleForm.value?.reset();
    model.value = undefined;
  } else if (stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_REVIEW) {
    stage.value = PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR;
  }
}

function composeCreateTradeRequest() {
  if (solution.value && model.value) {
    return {
      priceId: solution.value.id,
      hedgingProduct: solution.value.hedgingProduct,
      syncPendingFunds: false,
      premiumCurrency: model.value.sellCurrency,
      mvpCustomerEmail: model.value.mvpCustomerEmail,
      mvpInvoiceNumber: model.value.mvpInvoiceNumber,
      mvpDescription: model.value.mvpDescription,
      mvpFrequency: model.value.mvpFrequency,
      mvpNumPayments: model.value.mvpNumPayments,
      mvpFirstPaymentDate: formatDate(model.value.mvpFirstPaymentDate, 'yyyy-MM-dd'),
    } as CreateTradeRequest;
  }

  return undefined;
}

function nextStep() {
  if (stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR) {
    stage.value = PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_REVIEW;
  } else if (stage.value === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_REVIEW) {
    const tradeRequest = composeCreateTradeRequest();

    if (!tradeRequest) {
      throw 'Not enough date for making a trade request';
    }

    requestManager
      .sameOrCancelAndNew(
        'createTrade',
        services.trade
          .createTrade(tradeRequest, onBehalfOfClient.value?.id, {
            errors: { silent: true },
          })
          .pipe(
            mergeMap((idEntity) =>
              waitForEntityCreation(() => services.trade.getTrade(idEntity.id, { errors: { silent: true } }))
            )
          ),
        tradeRequest
      )
      .subscribe(
        (response) => {
          if (response !== null) {
            toast.success('Payment schedule booked successfully!');
            stage.value = PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR;
            paymentScheduleForm.value?.reset();
            model.value = undefined;
          }
        },
        () => {
          toast.error('There was an error and booking the payment schedule has failed!');
          stage.value = PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR;
          paymentScheduleForm.value?.reset();
          model.value = undefined;
        }
      );
  }
}

function updateValidations(anyInvalid: boolean) {
  isInvalid.value = anyInvalid;
}

function updateLoading(loading: boolean) {
  isLoading.value = loading;
}
</script>

<template>
  <div class="section">
    <div class="title-bar mb-4">
      <h2 class="mb-0">{{ title }}</h2>
      <PaymentScheduleForm
        v-if="stage === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_CREATOR"
        :disabledDates.sync="disabledDates"
        :solution.sync="solution"
        :model.sync="model"
        ref="paymentScheduleForm"
        @update:isInvalid="updateValidations"
        @update:isLoading="updateLoading"
      />
      <PaymentScheduleReview
        v-else-if="stage === PaymentScheduleCreatorSteps.PAYMENT_SCHEDULE_REVIEW && solution && model && disabledDates"
        :solution.sync="solution"
        :model="model"
        :isBooking="requestManager.requestStates.createTrade === 'pending'"
        :disabledDates="disabledDates"
        @update:isLoading="updateLoading"
        @update:isInvalid="updateValidations"
      />
      <div class="d-flex justify-content-end mt-2">
        <VButton
          class="btn-secondary mr-2"
          @click="backStep"
          :disabled="isLoading || requestManager.requestStates.createTrade === 'pending'"
        >
          {{ secondaryButton }}
        </VButton>
        <VButton
          class="btn-primary ml-2"
          @click="nextStep"
          :loading="isLoading || requestManager.requestStates.createTrade === 'pending'"
          :disabled="isInvalid"
        >
          {{ primaryButton }}
        </VButton>
      </div>
    </div>
  </div>
</template>
