<script lang="ts" setup>
import { BModal } from 'bootstrap-vue';
import { IncurCostsType, incurCostsTypeLabels, Trade, TradeStatus } from 'ah-api-gateways';
import { mergeMap } from 'rxjs/operators';
import { waitForEntityChange } from 'ah-requests';
import { radioField } from 'ah-common-lib/src/form/models';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { reactive, ref, computed } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useTradeState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useToast } from 'ah-common-lib/src/toast';

const toast = useToast();
const tradeState = useTradeState();
const onBehalfOfClient = useOnBehalfOf();

const emit = defineEmits<{
  (e: 'cancel-trade-executed'): void;
}>();

const props = defineProps<{
  trade: Trade;
}>();

const cancelTradeModal = ref<BModal | null>(null);

const state = reactive({
  closingCost: null as number | null,
  modalShown: false as boolean,
  lpCancelPnlAmount: null as number | null,
  lpCancelPnlAmountCurrency: null as string | null,
  refreshComponentTimeout: null as number | null,
});

const requestManager = useRequestManager({
  exposeToParent: ['cancelTradeRequest'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'cancelTradeRequest') {
      continueCancelTrade();
    }
  },
});

const incurTypeOptions = computed(() => {
  return Object.keys(incurCostsTypeLabels).map((k) => {
    return {
      value: k,
      label: incurCostsTypeLabels[k as IncurCostsType],
    };
  });
});

const incurCostsForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'incurCostsForm',
    fieldType: 'form',
    fields: [
      radioField('incurCost', '', incurTypeOptions.value, {
        required: true,
        clearValue: '',
        defaultValue: IncurCostsType.AH,
        fieldWrapperClass: 'radio-form-field',
      }),
    ],
  }),
  validation: null,
});

function loadTradeCancelationCosts() {
  return requestManager.manager
    .sameOrCancelAndNew(
      'getTrade',
      tradeState.services.trade.getTradeCostsOfClosing(props.trade.id, { errors: { silent: true } })
    )
    .toPromise()
    .then(
      (cost) => {
        state.lpCancelPnlAmountCurrency = cost.currency;
        state.lpCancelPnlAmount = cost.lpCancelPnlAmount;
        state.closingCost = cost.cancelFeeGbpAmount;
      },
      (e) => {
        if (e.response?.status !== 502) {
          toast?.error('An unexpected problem has occurred. Please try again later.');
        }
        if (e.response?.status === 502) {
          toast?.error(e.response?.data.message);
        }
        throw e;
      }
    );
}

function hide() {
  state.modalShown = false;
}

function refreshComponent() {
  state.refreshComponentTimeout = window.setTimeout(() => {
    if (state.modalShown) {
      loadTradeCancelationCosts()
        .catch(() => {
          hide();
        })
        .finally(() => {
          if (state.modalShown) {
            refreshComponent();
          }
        });
    }
  }, MINUTE);
}

/**
 * Display cancellation modal if costs are available
 */
function tryDisplayCancelModal() {
  if (!state.modalShown) {
    loadTradeCancelationCosts().then(() => {
      state.modalShown = true;
      refreshComponent();
    });
  }
}

function clearComponentRefresh() {
  if (state.refreshComponentTimeout !== null) {
    clearTimeout(state.refreshComponentTimeout);
    state.refreshComponentTimeout = null;
  }
}

function onModalHidden() {
  state.closingCost = null;
  state.lpCancelPnlAmount = null;
  state.lpCancelPnlAmountCurrency = null;
  clearComponentRefresh();
}

const isAHUser = computed(() => tradeState.store.useAuthStore().isAHUser);

const incurCost = computed(() => {
  if (incurCostsForm) {
    return incurCostsForm.form.incurCost;
  }

  return null;
});

function continueCancelTrade() {
  if (!onBehalfOfClient.value || !isAHUser.value) {
    throw 'Trade cancelation is an admin action only';
  }
  clearComponentRefresh();

  requestManager.manager
    .cancelAndNew(
      'cancelTradeRequest',
      tradeState.services.trade
        .cancelTrade(props.trade.id, onBehalfOfClient.value!.id, isAHUser.value, incurCost.value)
        .pipe(
          mergeMap(() =>
            waitForEntityChange(
              () => tradeState.services.trade.getTrade(props.trade.id, { errors: { silent: true } }),
              (response) =>
                response.status === TradeStatus.CANCELED || response.status === TradeStatus.REMAINING_CANCELED
            )
          )
        )
    )
    .subscribe(
      () => {
        tradeState.toast.success('Trade cancelled successfully');
        emit('cancel-trade-executed');
        hide();
      },
      () => {
        refreshComponent();
      }
    );
}
</script>

<template>
  <span>
    <BModal
      title="Cancel Trade"
      modal-class="side-modal"
      ref="cancelTradeModal"
      hide-footer
      v-model="state.modalShown"
      @hidden="onModalHidden"
    >
      <div class="buttons text-sm-center text-md-left">
        <p><b>Cancelation costs</b></p>
        <p class="mb-4 text-small">You’ll need to pay a fee for the trade to be canceled.</p>
        <span class="text-small"
          ><b>Cost of closing: </b
          ><span class="text-small ml-2">GBP {{ formatCurrencyValue(state.closingCost) }}</span></span
        >
        <p class="text-small mb-2">
          <b>Liquidity provider profit and loss: </b>
          <span class="text-small ml-2">
            <LoadingIcon
              v-if="requestManager.manager.requestStates.getTradeRollingForwardCosts === 'pending'"
              class="svg"
            /><span v-else>
              {{ state.lpCancelPnlAmountCurrency }} {{ formatCurrencyValue(state.lpCancelPnlAmount) }}
            </span>
          </span>
        </p>
        <BRow class="mt-4">
          <BCol cols="12"
            ><span class="text-small"><b>Who will incur the costs?</b></span></BCol
          >
          <BCol cols="12"><ValidatedForm :fm="incurCostsForm.form" /></BCol>
        </BRow>
        <p class="mt-3 mb-4">Are you sure you wish to close this trade?</p>
        <VButton class="btn-secondary mr-2" @click="hide">Cancel</VButton>
        <VButton class="btn-success" :loading="requestManager.manager.anyPending" @click="continueCancelTrade"
          >Continue</VButton
        >
      </div>
    </BModal>
    <slot>
      <VButton
        blurOnClick
        class="button-success"
        :loading="requestManager.manager.anyPending"
        @click="tryDisplayCancelModal()"
        >Cancel Trade</VButton
      >
    </slot>
  </span>
</template>

<style lang="scss" scoped>
::v-deep .radio-form-field {
  padding: 0 7.5px;
  .field-group-field {
    flex-grow: 1;
    .custom-control {
      margin-right: 5rem;
      margin-bottom: 0.7rem;
    }
  }
}
</style>
