export enum FeatureFlag {
  PRICING_SHOW_CLIENT_COSTS = 'PRICING_SHOW_CLIENT_COSTS',
  ALLOW_SPOT_TRADING_WITH_WALLET_BALANCE = 'ALLOW_SPOT_TRADING_WITH_WALLET_BALANCE',
  SUCDEN_TRADING_ENABLED = 'SUCDEN_TRADING_ENABLED',
  VARIATION_MARGIN_AND_LIMITS_FEATURE = 'VARIATION_MARGIN_AND_LIMITS_FEATURE',
  MVP_OPTIONS_PAYMENTS_SCHEDULE = 'MVP_OPTIONS_PAYMENTS_SCHEDULE',
  TENOR_LIMITS_FORWARD_FLAG = 'TENOR_LIMITS_FORWARD_FLAG',
  TENOR_LIMITS_OPTIONS_FLAG = 'TENOR_LIMITS_OPTIONS_FLAG',
}

export enum FeatureFlagTreatment {
  ON = 'on',
  OFF = 'off',
}

export interface FeatureFlagRequest {
  featureFlag: FeatureFlag;
}

export interface FeatureFlagResponse extends FeatureFlagRequest {
  treatment: FeatureFlagTreatment;
}
