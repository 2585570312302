<script setup lang="ts">
import { onMounted, ref, computed, useSlots, onBeforeUnmount } from 'vue';

const props = defineProps({
  textContent: {
    type: String,
    default: '',
  },
});

const checkOverflow = () => {
  if (textBlock.value) {
    const element = textBlock.value;
    textOverflow.value = element.scrollWidth > element.clientWidth;
  }
};

const textOverflow = ref(false);
const textBlock = ref<HTMLElement>();
const resizeObserver = new ResizeObserver(checkOverflow);

const slots = useSlots();

const tooltipText = computed(() => {
  if (!textOverflow.value) {
    return '';
  }
  if (slots.default) {
    const slot = slots.default();
    return slot[0].text || slot[0].elm?.textContent || props.textContent;
  }
  return props.textContent;
});

onMounted(() => {
  checkOverflow();

  if (textBlock.value) {
    resizeObserver.observe(textBlock.value);
  }
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});
</script>
<template>
  <span ref="textBlock" v-b-tooltip="tooltipText">
    <slot>{{ textContent }}</slot>
  </span>
</template>
