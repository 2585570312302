<script setup lang="ts">
import { ref } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { Client, OnboardingIndividualInfo } from 'ah-api-gateways';

const props = defineProps<{ applicant: OnboardingIndividualInfo; client: Client }>();

const infoBlock = ref<InformationBlock[]>([
  { label: 'Title', key: 'title', cols: 4 },
  { label: 'First Name', key: 'firstName', cols: 4 },
  { label: 'Last Name', key: 'lastName', cols: 4 },
  { label: 'Email Address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 4 },
  { label: 'Job Title', key: 'jobTitle', cols: 4 },
  { label: 'Where Did you Hear From Us?', key: 'reference', cols: 4 },
]);

const model = { ...props.applicant, reference: props.client.questionnaireAnswer };
</script>

<template>
  <div class="card-block" x-test-name="contact-details-review">
    <div class="mb-3">
      <h2>Contact Details</h2>
    </div>
    <InfoBlock emptyValue="-" :model="model" :info="infoBlock" />
  </div>
</template>

<style lang="scss" scoped>
h2 {
  font-size: $h3-font-size;
  margin-bottom: 0;
}

::v-deep {
  label {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }
}
</style>
