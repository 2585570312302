export const defaultFormatter = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 4, minimumFractionDigits: 2 });
export const defaultRateFormatter = new Intl.NumberFormat('en-GB', {
  maximumFractionDigits: 4,
  minimumFractionDigits: 4,
});

export function formatCurrencyValue(value: any, defaultEmpty = '-', formatter = defaultFormatter) {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return formatter.format(value);
  }
  return defaultEmpty;
}

export function getLocaleSeparators(formatter = defaultFormatter) {
  const separators = formatter.format(1111.1).replace(/[10]/g, '');

  return {
    thousandSeparator: separators[0],
    decimalSeparator: separators[1],
  };
}

// Allows for leading zeroes
export function formatCurrencyString(value: string, formatter = defaultFormatter) {
  const cleanValue = cleanCurrencyString(value, formatter);
  const number = parseFloat(cleanValue);

  let out = formatter.format(number);

  const leadingZeroes = countLeadingZeroes(cleanValue);

  if (leadingZeroes > 0) {
    out = setLeadingZeroes(out, leadingZeroes, formatter);
  }

  return out;
}

function setLeadingZeroes(value: string, leadingZeroes: number, formatter = defaultFormatter) {
  const separators = formatter.format(1111.1).replace(/[10]/g, '');
  const thousandSeparator = separators[0];
  const currentLeadingZeroes = countLeadingZeroes(cleanCurrencyString(value, formatter));
  let digitCount = countLastSeparatorIndex(value, formatter);

  for (let i = currentLeadingZeroes; i < leadingZeroes; i++) {
    if (digitCount === 3) {
      digitCount = 0;
      value = thousandSeparator + value;
    }
    value = '0' + value;
    digitCount += 1;
  }

  return value;
}

function countLastSeparatorIndex(value: string, formatter = defaultFormatter) {
  const separators = getLocaleSeparators(formatter);

  let digitCount = value.indexOf(separators.thousandSeparator);
  if (digitCount === -1) {
    digitCount = value.indexOf(separators.decimalSeparator);
  }
  if (digitCount === -1) {
    digitCount = value.length;
  }

  return digitCount;
}

function countLeadingZeroes(cleanValue: string) {
  if (cleanValue.startsWith('-')) {
    cleanValue = cleanValue.substr(1);
  }

  for (let i = 0; i < cleanValue.length; i++) {
    if (cleanValue[i] !== '0') {
      return i;
    }
  }

  return 0;
}

export function cleanCurrencyString(
  stringNumber: string,
  formatter = defaultFormatter,
  removeDuplicateSeparators = false
) {
  const separators = getLocaleSeparators(formatter);
  const thousandSeparatorRegexp = new RegExp(`\\${separators.thousandSeparator}`, 'g');
  const decimalSeparatorRegexp = removeDuplicateSeparators
    ? new RegExp(`[\\${separators.decimalSeparator}]+`, 'g')
    : new RegExp(`\\${separators.decimalSeparator}`, 'g');

  let out = stringNumber.replace(thousandSeparatorRegexp, '').replace(decimalSeparatorRegexp, '.');

  if (removeDuplicateSeparators) {
    // Find duplicate decimal separator, and clean it (i.e. 10000.00.00 => 10000.00)
    const dupeDecimalIndex = out.indexOf(separators.decimalSeparator, out.indexOf(separators.decimalSeparator) + 1);
    if (dupeDecimalIndex > -1) {
      out = out.substr(0, dupeDecimalIndex);
    }
  }

  return out;
}

export function parseCurrencyValue(stringNumber: string, formatter = defaultFormatter) {
  return parseFloat(cleanCurrencyString(stringNumber, formatter));
}

export const commonCurrencies = {
  AED: 'United Arab Emirates Dirham',
  AFA: 'Afghan afghani',
  AFN: 'Afghan Afghani',
  ALL: 'Albanian Lek',
  LVL: 'Latvian Lats',
  EEK: 'Estonian Kroon',
  XFO: 'Gold Franc',
  LTL: 'Lithuanian Litas',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Angolan Kwanza',
  AOR: 'Readjusted Angolan Kwanza',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AWG: 'Aruban Florin',
  AZN: 'Azerbaijani Manat',
  BAM: 'Bosnia-Herzegovina Convertible Mark',
  BBD: 'Barbadian Dollar',
  BDT: 'Bangladeshi Taka',
  BGN: 'Bulgarian Lev',
  BHD: 'Bahraini Dinar',
  BIF: 'Burundian Franc',
  BMD: 'Bermudan Dollar',
  BND: 'Brunei Dollar',
  BOB: 'Bolivian Boliviano',
  BRL: 'Brazilian Real',
  BSD: 'Bahamian Dollar',
  BTC: 'Bitcoin',
  BTN: 'Bhutanese Ngultrum',
  BWP: 'Botswanan Pula',
  BYN: 'Belarusian Ruble',
  BZD: 'Belize Dollar',
  CAD: 'Canadian Dollar',
  CDF: 'Congolese Franc',
  CHF: 'Swiss Franc',
  CLF: 'Chilean Unit of Account (UF)',
  CLP: 'Chilean Peso',
  CNH: 'Chinese Yuan (Offshore)',
  CNY: 'Chinese Yuan',
  COP: 'Colombian Peso',
  CRC: 'Costa Rican Colón',
  CUC: 'Cuban Convertible Peso',
  CUP: 'Cuban Peso',
  CVE: 'Cape Verdean Escudo',
  CZK: 'Czech Republic Koruna',
  DJF: 'Djiboutian Franc',
  DKK: 'Danish Krone',
  DOP: 'Dominican Peso',
  DZD: 'Algerian Dinar',
  EGP: 'Egyptian Pound',
  ERN: 'Eritrean Nakfa',
  ETB: 'Ethiopian Birr',
  EUR: 'Euro',
  FJD: 'Fijian Dollar',
  FKP: 'Falkland Islands Pound',
  GBP: 'British Pound Sterling',
  GEL: 'Georgian Lari',
  GGP: 'Guernsey Pound',
  GHS: 'Ghanaian Cedi',
  GIP: 'Gibraltar Pound',
  GMD: 'Gambian Dalasi',
  GNF: 'Guinean Franc',
  GTQ: 'Guatemalan Quetzal',
  GYD: 'Guyanaese Dollar',
  HKD: 'Hong Kong Dollar',
  HNL: 'Honduran Lempira',
  HRK: 'Croatian Kuna',
  HTG: 'Haitian Gourde',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Sheqel',
  IMP: 'Manx pound',
  INR: 'Indian Rupee',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  ISK: 'Icelandic Króna',
  JEP: 'Jersey Pound',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KGS: 'Kyrgystani Som',
  KHR: 'Cambodian Riel',
  KMF: 'Comorian Franc',
  KPW: 'North Korean Won',
  KRW: 'South Korean Won',
  KWD: 'Kuwaiti Dinar',
  KYD: 'Cayman Islands Dollar',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Laotian Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lankan Rupee',
  LRD: 'Liberian Dollar',
  LSL: 'Lesotho Loti',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MGA: 'Malagasy Ariary',
  MKD: 'Macedonian Denar',
  MMK: 'Myanma Kyat',
  MNT: 'Mongolian Tugrik',
  MOP: 'Macanese Pataca',
  MRO: 'Mauritanian Ouguiya (pre-2018)',
  MRU: 'Mauritanian Ouguiya',
  MUR: 'Mauritian Rupee',
  MVR: 'Maldivian Rufiyaa',
  MWK: 'Malawian Kwacha',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  MZN: 'Mozambican Metical',
  NAD: 'Namibian Dollar',
  NGN: 'Nigerian Naira',
  NIO: 'Nicaraguan Córdoba',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese Rupee',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PAB: 'Panamanian Balboa',
  PEN: 'Peruvian Nuevo Sol',
  PGK: 'Papua New Guinean Kina',
  PHP: 'Philippine Peso',
  PKR: 'Pakistani Rupee',
  PLN: 'Polish Zloty',
  PYG: 'Paraguayan Guarani',
  QAR: 'Qatari Rial',
  RON: 'Romanian Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Russian Ruble',
  RWF: 'Rwandan Franc',
  SAR: 'Saudi Riyal',
  SBD: 'Solomon Islands Dollar',
  SCR: 'Seychellois Rupee',
  SDG: 'Sudanese Pound',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SHP: 'Saint Helena Pound',
  SLL: 'Sierra Leonean Leone',
  SOS: 'Somali Shilling',
  SRD: 'Surinamese Dollar',
  SSP: 'South Sudanese Pound',
  STD: 'São Tomé and Príncipe Dobra (pre-2018)',
  STN: 'São Tomé and Príncipe Dobra',
  SVC: 'Salvadoran Colón',
  SYP: 'Syrian Pound',
  SZL: 'Swazi Lilangeni',
  THB: 'Thai Baht',
  TJS: 'Tajikistani Somoni',
  TMT: 'Turkmenistani Manat',
  TND: 'Tunisian Dinar',
  TOP: "Tongan Pa'anga",
  TRY: 'Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Ukrainian Hryvnia',
  UGX: 'Ugandan Shilling',
  USD: 'United States Dollar',
  UYU: 'Uruguayan Peso',
  UZS: 'Uzbekistan Som',
  VEF: 'Venezuelan Bolívar Fuerte',
  VND: 'Vietnamese Dong',
  VUV: 'Vanuatu Vatu',
  WST: 'Samoan Tala',
  XAF: 'CFA Franc BEAC',
  XAG: 'Silver Ounce',
  XAU: 'Gold Ounce',
  XCD: 'East Caribbean Dollar',
  XDR: 'Special Drawing Rights',
  XOF: 'CFA Franc BCEAO',
  XPD: 'Palladium Ounce',
  XPF: 'CFP Franc',
  XPT: 'Platinum Ounce',
  YER: 'Yemeni Rial',
  ZAR: 'South African Rand',
  ZMW: 'Zambian Kwacha',
  ZWL: 'Zimbabwean Dollar',
};
