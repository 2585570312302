<script setup lang="ts">
import {
  Beneficiary,
  Wallet,
  beneficiaryName,
  Transaction,
  TransactionExternal,
  ScheduledTransaction,
  BeneficiaryCategory,
  BankingScheme,
  FeeChargeType,
  Client,
  AuthorityType,
  FeePaymentType,
} from 'ah-api-gateways';
import { mergeMap } from 'rxjs/operators';
import {
  makeFormModel,
  setState,
  getChildModel,
  submitForm,
  getState,
  scrollToValidation,
  scrollToElement,
} from 'ah-common-lib/src/form/helpers';
import { financialAmountField } from 'ah-common-lib/src/form/models';
import { HttpError, ApiError, GenericErrorCodes, BeneficiaryManagerErrorCodes } from 'ah-requests';
import FieldWalletInfoOrErrors from './FieldWalletInfoOrErrors.vue';
import {
  requiredIfStateValue,
  smallerThanOrEqualsParam,
  optional,
  biggerThanOrEqualsParam,
} from 'ah-common-lib/src/form/validators';
import BeneficiaryAccountSelector from 'ah-beneficiaries/src/components/BeneficiaryAccountSelector.vue';
import CurrencyFundingFee from 'ah-trades/src/components/fees/CurrencyFundingFee.vue';
import PaymentReferenceForm from 'ah-trades/src/components/forms/PaymentReferenceForm.vue';
import CurrencySelectInput from 'ah-trades/src/components/currency/CurrencySelectInput.vue';
import { BModal } from 'bootstrap-vue';
import BankAccountsTable from 'ah-beneficiaries/src/components/bankAccounts/BankAccountsTable.vue';
import BankAccountEditModal from 'ah-beneficiaries/src/components/bankAccounts/BankAccountEditModal.vue';
import SchedulePaymentForm from './SchedulePaymentForm.vue';
import ChargeTypeForm from './ChargeTypeForm.vue';
import PaymentReasonForm from './PaymentReasonForm.vue';
import { WalletOwnerData } from 'ah-common-lib/src/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { computed, inject, reactive, ref, watch } from 'vue';
import { ON_BEHALF_OF_CLIENT_INJECT_KEY } from 'ah-common-lib/src/onBehalfOf/constants';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useWalletState } from '..';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { paymentPurposeForm } from 'ah-payments/src/formSchemas/paymentFormSchemas';
/**
 * Withdraw from wallet
 *
 * Component allows withdrawing from a wallet to a specific beneficiary (defaults to the client's self beneficiary)
 *
 */

const props = withDefaults(
  defineProps<{
    /**
     * Whether to use self beneficiary
     *
     * If set, targetBeneficiary will be ignored and self beneficiary will be considered the target
     *
     * Defaults to false, any value other than false is considered truthy
     */
    selfBeneficiary?: boolean | string;
    /**
     * Possible amend an existing scheduled payment
     *
     * Ignored if selfBeneficiary is truthy
     */
    transaction?: Transaction | null;
    /**
     * Possible target wallet
     *
     * If set, currency selection is disabled, and is set to the wallet's currency
     */
    targetWallet?: Wallet;
    /**
     * Possible target beneficiary
     *
     * Ignored if selfBeneficiary is truthy
     *
     * If set, and wallet is not set, currency selection is constrained to the currencies for which the beneficiary has accounts
     */
    targetBeneficiary?: Beneficiary;
    /**
     * Whether to allow overdrafting
     */
    allowOverdraft?: boolean | string;
    /**
     * Whether to allow overdrafting
     */
    allowScheduledPayment?: boolean | string;
  }>(),
  {
    selfBeneficiary: false,
    transaction: null,
    allowOverdraft: false,
    allowScheduledPayment: false,
  }
);

const state = reactive<{
  onBehalfOfClient: Client | null;
  selfBeneficiaries: Beneficiary[] | null;
  paymentReason: string | null;
  paymentReference: string;
  selectedCurrency: string;
  scheduledDate: Date | null;
  chargeType?: FeeChargeType;
  selectedBeneficiary: Beneficiary | null;
}>({
  onBehalfOfClient: inject(ON_BEHALF_OF_CLIENT_INJECT_KEY, null),
  selfBeneficiaries: null,
  paymentReason: '',
  paymentReference: '',
  selectedCurrency: '',
  scheduledDate: null,
  selectedBeneficiary: null,
});

const emit = defineEmits<{
  /*
   * called when the action is cancelled
   */
  (e: 'cancel', value: void): void;
  /*
   * called when the action finishes successfully
   */
  (e: 'withdraw-succeded', value: void): void;
}>();

const amountToWithdrawFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'amountToWithdraw',
    fieldType: 'form',
    fields: [
      financialAmountField(
        'amount',
        '',
        {
          hideErrorMessages: true,
          inputClass: 'form-control text-right',
          fieldWrapperClass: 'col col-12 mb-0',
          availableBalance: 0,
          min: 0.01,
          max: Infinity,
          errorMessages: {
            min: 'Amount cannot be empty or zero.',
            max: 'The amount exceeds the payment limit',
            required: '',
          },
        },
        {
          min: biggerThanOrEqualsParam('amount', 'min'),
          max: smallerThanOrEqualsParam('amount', 'max'),
          required: requiredIfStateValue('amount'),
        }
      ),
    ],
  }),
  validation: null,
});

const paymentPurposeDef = reactive<FormDefinition>({
  form: makeFormModel(paymentPurposeForm()),
  validation: null,
});

const oboWithdrawModal = ref<InstanceType<typeof BModal> | null>(null);

const schedulePaymentForm = ref<InstanceType<typeof SchedulePaymentForm> | null>(null);

const chargeTypeForm = ref<InstanceType<typeof ChargeTypeForm> | null>(null);

const paymentReferenceForm = ref<InstanceType<typeof PaymentReferenceForm> | null>(null);

const paymentReasonForm = ref<InstanceType<typeof PaymentReasonForm> | null>(null);

const bankingScheme = computed(() => beneficiary.value?.bankingScheme);

const isEditingPayment = computed(() => !useSelfBeneficiary.value && !!props.transaction);

const isSendingMoney = computed(() => !!props.targetBeneficiary);

const useSelfBeneficiary = computed(() => props.selfBeneficiary !== false);

const useChargeType = computed(() => beneficiary.value?.bankingScheme === BankingScheme.SWIFT);

const beneficiaries = computed(() => state.selfBeneficiaries?.filter((b) => walletCurrency.value === b.currency));

const walletCurrency = computed(() => props.targetWallet?.currency ?? state.selectedCurrency);

const isPaymentPurposeRequired = ref(false);

const allFieldsFilled = computed(
  () =>
    !!(
      amountToWithdrawFormDef.form.amount &&
      beneficiary.value &&
      wallet.value &&
      state.paymentReason &&
      (isPaymentPurposeRequired.value ? paymentPurposeDef.form.paymentPurpose : true)
    )
);

const amountWithdrawField = computed(() => getChildModel(amountToWithdrawFormDef.form, 'amount')!);

const isOverdraftPayment = computed(() => amountToWithdrawFormDef.form.amount > (wallet.value?.availableBalance || 0));

const shouldAllowOverdraft = computed(() => props.allowOverdraft !== false);

const shouldAllowScheduledPayment = computed(() => props.allowScheduledPayment !== false);

const beneficiary = computed(() => props.targetBeneficiary || state.selectedBeneficiary);

const overdraftMessage = computed(
  () =>
    `Your current balance is less than the payment amount. ${
      shouldAllowOverdraft.value
        ? 'On your selected payment date, the payment will be put into a pending queue until funds become available to process it.'
        : ''
    }`
);

const isEditingQueuedPayment = computed(
  () => isEditingPayment.value && (props.transaction as TransactionExternal)?.scheduleType === 'QUEUE'
);

const parsedBankingScheme = computed(() => {
  if (beneficiary.value) {
    return (
      walletState.store.useSettingsStore().getPaymentRail({
        currency: beneficiary.value.currency,
        bankingScheme: beneficiary.value.bankingScheme,
      })[0]?.label || beneficiary.value.bankingScheme
    );
  }
  return null;
});

const wallet = computed(() => {
  return (
    props.targetWallet ??
    walletState.store.useWalletsStore().getWallet(
      walletCurrency.value,
      state.onBehalfOfClient
        ? {
            isPartner: !(state.onBehalfOfClient?.id || walletState.store.useAuthStore().isClientUser),
            id: state.onBehalfOfClient?.id,
          }
        : undefined
    )
  );
});

const allowedCurrencies = computed(() => {
  if (props.targetWallet) {
    return [props.targetWallet.currency];
  }
  if (beneficiary.value) {
    return [beneficiary.value.currency];
  }
  return [];
});

const canManageBeneficiaries = computed(() => {
  return state.onBehalfOfClient || walletState.store.useAuthStore().hasAuthorities(AuthorityType.MANAGE_BENEFICIARIES);
});

const getBeneficiaryName = computed(() => {
  if (beneficiary.value) {
    return beneficiaryName(beneficiary.value);
  }
  return '';
});

const requestManager = useRequestManager({
  exposeToParent: ['getBeneficiary', 'loadWallet', 'getSelfBeneficiaries'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'getBeneficiary') {
      onTypeOfActionChange();
    } else if (k === 'loadWallet') {
      onWalletCurrencyChange();
    } else if (k === 'getSelfBeneficiaries') {
      loadSelfBeneficiaries();
    }
  },
});

const walletState = useWalletState();

function loadPaymentPurposeFields() {
  if (wallet.value && beneficiary.value) {
    const params = {
      currency: wallet.value?.currency,
      beneficiaryType: beneficiary.value?.type,
      bankAccountCountry: beneficiary.value?.bankCountry,
      bankingScheme: beneficiary.value.bankingScheme,
    };
    requestManager.manager
      .sameOrNewPromise(
        'loadPaymentPurpose',
        () => walletState.store.useSettingsStore().loadBankPurposeCodes({ params }),
        params
      )
      .then((purposeCodesResponse) => {
        isPaymentPurposeRequired.value = purposeCodesResponse.mandatory;
        const purposeCodesOptions = purposeCodesResponse?.purposeCodes.map((code) => {
          return {
            value: code.purposeCode,
            label: code.purposeDescription,
          };
        });
        const validPurposeCode = purposeCodesOptions.find(
          (option) => option.value === state.selectedBeneficiary?.purposeCode
        )?.value;
        const model = getChildModel(paymentPurposeDef.form, 'paymentPurpose');
        if (model) {
          setState(model, 'options', purposeCodesOptions);
        }
        paymentPurposeDef.form.paymentPurpose = validPurposeCode ?? '';
      });
  }
}

watch(
  () => props.targetWallet,
  () => {
    if (props.targetWallet) {
      state.selectedCurrency = props.targetWallet.currency;
    }
  },
  { immediate: true }
);

watch(
  () => props.targetBeneficiary,
  () => {
    if (props.targetBeneficiary && !useSelfBeneficiary.value) {
      state.selectedCurrency = allowedCurrencies.value[0] ?? 'EUR';
    }
  },
  { immediate: true }
);

watch([beneficiary, wallet], loadPaymentPurposeFields, { immediate: true });

watch(
  beneficiary,
  (newBeneficiary, oldBeneficiary) => {
    if (
      oldBeneficiary &&
      newBeneficiary &&
      newBeneficiary.bankingScheme === oldBeneficiary.bankingScheme &&
      newBeneficiary.currency === oldBeneficiary.currency
    ) {
      return;
    }

    if (beneficiary.value) {
      walletState.store
        .useSettingsStore()
        .loadPaymentLimit({
          force: false,
          bankingScheme: beneficiary!.value.bankingScheme,
          currency: beneficiary!.value.currency,
        })
        .then((response) => {
          if (response.hasLimit) {
            setState(getChildModel(amountToWithdrawFormDef.form, 'amount')!, 'max', response.amountLimit);
            setLimitErrorMessage(response.amountLimit);
          } else {
            setState(getChildModel(amountToWithdrawFormDef.form, 'amount')!, 'max', Infinity);
            setLimitErrorMessage(Infinity);
          }
        });
    }
  },
  { immediate: true }
);

function onTypeOfActionChange() {
  if (isEditingPayment.value) {
    const beneficiaryId = (props.transaction as TransactionExternal).otherBeneficiaryId;
    state.scheduledDate = new Date((props.transaction as ScheduledTransaction).executionDate);
    state.paymentReason = props.transaction?.description || null;
    if (beneficiaryId) {
      requestManager.manager
        .sameOrCancelAndNew('getBeneficiary', walletState.services.beneficiary.getBeneficiary(beneficiaryId))
        .subscribe((beneficiary) => (state.selectedBeneficiary = beneficiary));
      setTimeout(() => {
        amountToWithdrawFormDef.form.amount = (props.transaction as ScheduledTransaction).amount;
      }, 0);
    }
  }
}

watch(
  isEditingPayment,
  () => {
    onTypeOfActionChange();
  },
  { immediate: true }
);

watch(
  () => props.transaction,
  () => {
    if (props.transaction) {
      state.paymentReference = props.transaction.reference ?? '';
    }
  },
  { immediate: true }
);

function onWalletCurrencyChange() {
  if (state.selectedCurrency && !props.targetWallet) {
    const payload: {
      currency: string;
      force?: boolean;
      owner?: WalletOwnerData;
    } = { currency: state.selectedCurrency };

    if (state.onBehalfOfClient) {
      payload['owner'] = { isPartner: false, id: state.onBehalfOfClient?.id };
    }

    requestManager.manager.newPromise('loadWallet', walletState.store.useWalletsStore().loadCurrencyWallet(payload));
  }
}

watch(
  () => [state.selectedCurrency, state.onBehalfOfClient],
  () => {
    onWalletCurrencyChange();
  },
  { immediate: true }
);

function loadSelfBeneficiaries() {
  if (useSelfBeneficiary.value) {
    // if current withdraw is to a selfBeneficiary we should not allow overdraft
    // therefore we remake the form with a walletMaximum validation
    amountToWithdrawFormDef.form = makeFormModel({
      name: 'amountToWithdraw',
      fieldType: 'form',
      fields: [
        financialAmountField(
          'amount',
          '',
          {
            hideErrorMessages: true,
            inputClass: 'form-control text-right',
            availableBalance: 0,
            min: 0.01,
            errorMessages: {
              min: 'Amount cannot be empty or zero.',
              required: '',
            },
          },
          {
            min: biggerThanOrEqualsParam('amount', 'min'),
            walletMaximum: optional(smallerThanOrEqualsParam('amount', 'availableBalance')),
            required: requiredIfStateValue('amount'),
          }
        ),
      ],
    });

    const query =
      state.onBehalfOfClient || walletState.store.useAuthStore().isClientUser
        ? {
            clientId: state.onBehalfOfClient?.id ?? walletState.store.useAuthStore().loggedInIdentity?.client?.id,
            category: BeneficiaryCategory.CLIENT_PERSONAL,
          }
        : {
            category: BeneficiaryCategory.PARTNER_PERSONAL,
          };

    requestManager.manager
      .currentOrNew(
        'getSelfBeneficiaries',
        walletState.services.beneficiary.listBeneficiaries({ ...query, pageSize: 1000 })
      )
      .subscribe((response) => (state.selfBeneficiaries = response.list));
  }
}

watch(
  useSelfBeneficiary,
  () => {
    loadSelfBeneficiaries();
  },
  { immediate: true }
);

function onWalletChange() {
  if (wallet.value) {
    setState(getChildModel(amountToWithdrawFormDef.form, 'amount')!, 'availableBalance', wallet.value.availableBalance);
  }
}

watch(
  wallet,
  () => {
    onWalletChange();
  },
  { immediate: true }
);

onWalletChange();

function setLimitErrorMessage(amount: number) {
  const currencyValue = `${beneficiary.value!.currency} ${formatCurrencyValue(amount)}`;
  const message = `The amount exceeds the payment limit (${currencyValue} for ${parsedBankingScheme.value}). You can use SWIFT for larger amounts.`;
  const errorMessages = getState(getChildModel(amountToWithdrawFormDef.form, 'amount')!, 'errorMessages');
  setState(getChildModel(amountToWithdrawFormDef.form, 'amount')!, 'errorMessages', { ...errorMessages, max: message });
}

function cancel() {
  emit('cancel');
}

function submit() {
  if (isOverdraftPayment.value && !shouldAllowOverdraft.value) {
    return;
  }
  if (amountToWithdrawFormDef.validation && beneficiary.value && wallet.value) {
    submitForm(amountToWithdrawFormDef.validation);

    let scheduleValid = true;
    if (schedulePaymentForm.value) {
      schedulePaymentForm.value!.touchForms();
      scheduleValid = schedulePaymentForm.value!.valid;
    }

    let feeTypeValid = true;
    if (chargeTypeForm.value) {
      chargeTypeForm.value!.touchForms();
      feeTypeValid = chargeTypeForm.value!.valid || true;
    }
    let reasonValid = true;
    if (paymentReasonForm.value) {
      paymentReasonForm.value.touchForms();
      reasonValid = paymentReasonForm.value!.valid;
    }
    let referenceValid = true;
    if (paymentReferenceForm.value) {
      paymentReferenceForm.value.touchForms();
      referenceValid = paymentReferenceForm.value.valid;
    }

    if (
      !amountToWithdrawFormDef.validation.$invalid &&
      scheduleValid &&
      feeTypeValid &&
      reasonValid &&
      referenceValid
    ) {
      if (state.onBehalfOfClient) {
        oboWithdrawModal.value!.show();
      } else {
        walletState.store
          .useAuthStore()
          .checkComplianceApproval({ showModal: true, client: state.onBehalfOfClient ?? undefined })
          .then(() => withdrawFunds());
      }
    } else if (amountToWithdrawFormDef.validation.$invalid) {
      scrollToValidation(amountToWithdrawFormDef.validation);
    } else if (!scheduleValid) {
      scrollToElement(schedulePaymentForm.value!.$el);
    }
  }
}

function withdrawFunds() {
  if (isOverdraftPayment.value && !shouldAllowOverdraft.value) {
    return;
  }
  let executionDate: string | undefined;
  if (props.transaction?.tradeId) {
    // executionDate is never set when amending payments attached to a Trade
    executionDate = undefined;
  } else if (state.scheduledDate) {
    executionDate = formatDate(state.scheduledDate, 'yyyy-MM-dd');
  } else if (isOverdraftPayment.value) {
    // if current payment is overdraft we should make it scheduled for today
    executionDate = formatDate(new Date(), 'yyyy-MM-dd');
  }

  const request = props.transaction?.paymentId
    ? requestManager.manager.currentOrNew(
        'updateWithdraw',
        walletState.services.payments
          .updateScheduledPayment(
            props.transaction.paymentId,
            {
              amount: amountToWithdrawFormDef.form.amount,
              reference: state.paymentReference || undefined,
              executionDate: executionDate,
              chargeType: (useChargeType.value && state.chargeType) || undefined,
              description: state.paymentReason || undefined,
              purposeCode: paymentPurposeDef.form.paymentPurpose,
            },
            state.onBehalfOfClient?.id,
            { errors: { silent: true } }
          )
          .pipe(
            mergeMap(() => {
              return walletState.store.useWalletsStore().waitForWalletChange({ id: wallet.value!.id });
            })
          )
      )
    : requestManager.manager.currentOrNew(
        'withdrawFunds',
        walletState.services.payments
          .createPayment(
            {
              walletId: wallet.value!.id,
              amount: amountToWithdrawFormDef.form.amount,
              beneficiaryId: beneficiary.value!.id,
              reference: state.paymentReference || undefined,
              executionDate: executionDate,
              description: state.paymentReason || undefined,
              chargeType: (useChargeType.value && state.chargeType) || undefined,
              purposeCode: paymentPurposeDef.form.paymentPurpose,
            },
            state.onBehalfOfClient?.id,
            { errors: { silent: true } }
          )
          .pipe(
            mergeMap(() => {
              return walletState.store.useWalletsStore().waitForWalletChange({ id: wallet.value!.id });
            })
          )
      );

  request.subscribe(
    () => {
      walletState.toast.success('Request successfully executed');
      emit('withdraw-succeded');
    },
    (err: HttpError) => {
      if (err.response?.data) {
        handleError(err.response.data);
      }
    }
  );
}

function handleError(apiError: ApiError) {
  if (apiError.code === GenericErrorCodes.BUSINESS_RULE_NOT_MET) {
    if (apiError.subErrors?.length && apiError.subErrors[0].field) {
      const field = getChildModel(amountToWithdrawFormDef.form, apiError.subErrors[0].field);
      if (field) {
        setState(field, 'errors', [
          {
            name: 'wmError',
            html: true,
            error: apiError.subErrors[0].message,
          },
        ]);
      } else {
        walletState.toast.error(apiError.subErrors[0].message);
      }
    } else {
      walletState.toast.error('An unexpected problem has occurred. Please try again later.');
    }
  } else if (apiError.code === BeneficiaryManagerErrorCodes.BUSINESS_RULE_NOT_MET && apiError.message) {
    walletState.toast.error(apiError.message);
  } else {
    walletState.toast.error('An unexpected problem has occurred. Please try again later.');
  }
}

function onSelfBeneficiarySelected(beneficiary: Beneficiary) {
  state.selectedBeneficiary = beneficiary;
}

if (props.targetBeneficiary && !useSelfBeneficiary.value) {
  state.selectedBeneficiary = { ...props.targetBeneficiary };
}

walletState.store.useSettingsStore().loadPaymentRails();
</script>

<template>
  <div>
    <BoxGrid alignH="start">
      <BoxGridBlock cols="12">
        <div>
          <label for="">{{ isSendingMoney ? 'You are sending:' : 'Amount to pay' }}</label>
        </div>
        <div class="row">
          <!-- sell currency -->
          <div class="col-5" v-if="allowedCurrencies.length > 1">
            <CurrencySelectInput :currency.sync="state.selectedCurrency" :allowedCurrencies="allowedCurrencies" />
          </div>

          <!-- sell amount -->
          <div v-bind:class="allowedCurrencies.length > 1 ? 'col-7' : 'col-12'">
            <ValidatedForm :fm="amountToWithdrawFormDef.form" :validation.sync="amountToWithdrawFormDef.validation">
              <template v-if="allowedCurrencies.length === 1" #amountToWithdraw.amount:prepend>
                <BInputGroupText>
                  {{ walletCurrency }}
                </BInputGroupText>
              </template>
            </ValidatedForm>
          </div>
        </div>
        <div class="row mt-3">
          <FieldWalletInfoOrErrors
            v-if="amountToWithdrawFormDef.validation && !requestManager.manager.anyPending"
            showAvailable
            class="col-12 field-group"
            :field="amountToWithdrawFormDef.validation.amount"
            :model="amountWithdrawField"
            :walletCurrency="walletCurrency"
            :amountToWithdraw="amountToWithdrawFormDef.form.amount"
            :overdraftMessage="overdraftMessage"
            :showOverdraftAsWarning="shouldAllowOverdraft"
            error-before-amount
          />
        </div>
      </BoxGridBlock>

      <BoxGridBlock
        cols="12"
        class="scheduled-payment-form mb-3"
        v-if="!useSelfBeneficiary && shouldAllowScheduledPayment"
      >
        <SchedulePaymentForm
          ref="schedulePaymentForm"
          class="mt-3 mb-n3"
          :scheduledDate="state.scheduledDate ?? undefined"
          @update:scheduledDate="state.scheduledDate = $event"
          :readonly="isEditingQueuedPayment"
        />
      </BoxGridBlock>

      <BoxGridBlock cols="12" v-if="!isSendingMoney && !useSelfBeneficiary">
        <div class="col col-12 mb-2">
          <label> Select Beneficiary</label>
          <InfoTooltip class="pb-1" :text="$ahBeneficiariesState.i18n.t('tooltips.beneficiary')" />
        </div>
        <BeneficiaryAccountSelector
          class="my-3"
          :beneficiary.sync="state.selectedBeneficiary"
          :allowedCurrencies="[walletCurrency]"
          v-bind="$attrs"
        />
      </BoxGridBlock>

      <BoxGridBlock cols="12" v-else-if="useSelfBeneficiary">
        <h3>Choose a {{ walletCurrency }} account to transfer the funds to</h3>
        <BankAccountsTable
          class="mt-3"
          showEmptyHeaders
          :selectedItems="state.selectedBeneficiary ? [state.selectedBeneficiary.id] : []"
          :data="{ list: beneficiaries }"
          @row-clicked="onSelfBeneficiarySelected"
          selectable
        />
        <BankAccountEditModal
          v-slot="slotProps"
          :currency="walletCurrency"
          @update:beneficiary="loadSelfBeneficiaries"
          v-if="canManageBeneficiaries"
        >
          <VButton @click="slotProps.showModal" class="btn-stroked mt-4 w-100"> Add bank account </VButton>
        </BankAccountEditModal>
      </BoxGridBlock>
      <BoxGridBlock cols="12" v-if="beneficiary">
        <PaymentReasonForm
          ref="paymentReasonForm"
          :reason.sync="state.paymentReason"
          :beneficiary="beneficiary"
          narrow
        />
      </BoxGridBlock>
      <BoxGridBlock cols="12" v-if="bankingScheme && state.selectedCurrency && beneficiary">
        <PaymentReferenceForm
          ref="paymentReferenceForm"
          narrow
          :reference.sync="state.paymentReference"
          :beneficiary="beneficiary"
        />
      </BoxGridBlock>
      <BoxGridBlock cols="12" v-if="isPaymentPurposeRequired">
        <h3 class="card-title">Payment Purpose</h3>
        <ValidatedForm :fm="paymentPurposeDef.form" :validation.sync="paymentPurposeDef.validation" />
      </BoxGridBlock>
      <BoxGridBlock cols="12" v-if="beneficiary">
        <ChargeTypeForm ref="chargeTypeForm" :chargeType.sync="state.chargeType" v-if="useChargeType" />
        <CurrencyFundingFee
          md="3"
          small
          v-if="bankingScheme && state.selectedCurrency && beneficiary"
          :paymentCurrency="state.selectedCurrency"
          :bankingScheme="bankingScheme"
          :beneficiary="beneficiary"
          :chargeType="state.chargeType"
          :feePaymentType="FeePaymentType.PAYMENT"
        />
      </BoxGridBlock>
    </BoxGrid>

    <div class="text-md-left text-sm-center my-4">
      <VButton @click="cancel" class="btn-secondary mr-2">Cancel</VButton>
      <VButton
        @click="submit"
        :disabled="!allFieldsFilled || (isOverdraftPayment && !shouldAllowOverdraft)"
        :loading="requestManager.manager.anyPending"
        >{{ useSelfBeneficiary ? 'Withdraw' : 'Confirm' }}</VButton
      >
    </div>
    <BModal
      :centered="true"
      ok-title="Confirm"
      cancel-variant="secondary"
      body-class="py-5 px-5 text-center"
      footer-class="justify-content-center pb-5"
      ref="oboWithdrawModal"
      @ok="withdrawFunds"
    >
      <div v-if="state.onBehalfOfClient">
        You are sending {{ amountToWithdrawFormDef.form.amount }} {{ state.selectedCurrency }} to
        {{ getBeneficiaryName }} on behalf of
        {{ state.onBehalfOfClient.name }}
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .custom-control {
  padding-top: 0.5rem;

  .custom-control-input:checked ~ .custom-control-label {
    font-weight: 500;
  }
}
</style>
