<script setup lang="ts">
import { AmountType, DrawdownQuotePriceResponse } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { TradeDetails } from 'src/models/trade';
import { PropType, computed } from 'vue';

const props = defineProps({
  tradeDetails: {
    type: Object as PropType<TradeDetails>,
    required: true,
  },
  tradePrice: {
    type: Object as PropType<DrawdownQuotePriceResponse | null>,
    required: false,
  },
  costTransparencyCurrency: {
    type: String as PropType<AmountType>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'update:costTransparencyCurrency', value: AmountType): void;
}>();

const showProtectionInSell = computed(() => {
  return props.costTransparencyCurrency === AmountType.SELL;
});

const currency = computed(() => {
  return showProtectionInSell.value ? props.tradeDetails.sellCurrency : props.tradeDetails.buyCurrency;
});

function changePremiumCurrency(amountType: AmountType) {
  emit('update:costTransparencyCurrency', amountType);
}

const ccy = computed(() => (props.tradePrice?.ccy1.amountType === props.costTransparencyCurrency ? 'ccy1' : 'ccy2'));

function cost(onlyDecimalsPlaces: boolean = false) {
  if (props.tradePrice) {
    if (props.tradePrice[ccy.value].clientCost) {
      return formatCurrencyValue(props.tradePrice[ccy.value].clientCost).split('.')[onlyDecimalsPlaces ? 1 : 0];
    }
  }

  return onlyDecimalsPlaces ? '00' : '0';
}

// FIXME remove the formatter when the API sends clientCostBasisPoints with 2 decimal places instead of four
const basisPointsAmount = computed(() => {
  if (props.tradePrice) {
    return formatCurrencyValue(
      props.tradePrice[ccy.value].clientCostBasisPoints,
      '0',
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    );
  }

  return 0;
});

const basisPointsAmountPercentage = computed(() => {
  if (props.tradePrice) {
    return formatCurrencyValue(
      props.tradePrice[ccy.value].clientCostBasisPoints / 100,
      '0',
      new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    );
  }

  return 0;
});
</script>

<template>
  <div>
    <VRow>
      <VCol cols="6">
        <h3>Real Time Transparency</h3>
      </VCol>
      <VCol cols="6">
        <VRow alignH="end" class="mr-2">
          <div class="currency-switcher">
            <span
              class="text-small"
              :class="{ selected: showProtectionInSell }"
              @click="changePremiumCurrency(AmountType.SELL)"
            >
              {{ tradeDetails.sellCurrency }}
            </span>
            <span
              class="text-small"
              :class="{ selected: !showProtectionInSell }"
              @click="changePremiumCurrency(AmountType.BUY)"
            >
              {{ tradeDetails.buyCurrency }}
            </span>
          </div>
        </VRow>
      </VCol>
    </VRow>
    <BoxGrid class="mb-3">
      <BoxGridItem cols="12">
        <div class="rates-box">
          <span class="pr-0"> The rate shown to you includes all costs. </span>
        </div>
      </BoxGridItem>
    </BoxGrid>

    <BoxGrid class="mb-3">
      <BoxGridItem cols="12" class="mb-3">
        <span class="pr-0">
          We believe in being transparent with clients about our markup so they can confidently compare our pricing, and
          trust that it will remain consistent without any surprises.
        </span>
      </BoxGridItem>

      <BoxGridItem cols="12">
        <BoxGrid class="ml-2">
          <BoxGridItem md="4" sm="12" class="total-costs mb-md-0 mb-sm-3">
            <span class="h3-font"
              >{{ currency }}
              <span class="bold"
                ><span class="h2-font">{{ cost() }}</span
                >.{{ cost(true) }}</span
              ></span
            >
            <p class="text-small text-secondary mb-0">ALT21's Profit</p>
          </BoxGridItem>

          <BoxGridItem class="pl-md-3" md="8" sm="12" align-self="end">
            <p class="text-small mb-0">
              All-in mark-up <span class="bold">{{ basisPointsAmount }} BP </span>(Basis Points) or
              <span class="bold">{{ basisPointsAmountPercentage }}% </span> of notional
            </p>
          </BoxGridItem>
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.currency-switcher {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;

  > span {
    cursor: pointer;
    display: inline-block;
    padding: 4px 16px;
    line-height: 1em;
    font-weight: $font-weight-bold;
    text-align: center;
    border: 2px solid;
    @include themedBorderColor($color-primary);
    @include themedTextColor($color-primary);

    &:first-child {
      border-right: 1px solid;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &:last-child {
      border-left: 1px solid;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    &.selected {
      @include themedBackgroundColor($color-primary);
      @include themedBorderColor($color-primary);
      color: $white;
    }
  }
}

.total-costs {
  padding: 1em;
  border: 2px solid;
  @include themedBorderColor($color-widgets-green);
  border-radius: 5px;
}

.bold {
  font-weight: $font-weight-bold;
}

.h2-font {
  font-size: $h2-font-size;
}

.h3-font {
  font-size: $h3-font-size;
}

.rates-box {
  padding: 1em;
  border-radius: 5px;
  @include themedProp(
    'background-color',
    getColorWithOffsetLightness($color-primary, +40%),
    getColorWithOffsetLightness($color-primary, +5%)
  );
}
</style>
