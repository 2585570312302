<script setup lang="ts">
import { computed, PropType, watch } from 'vue';
import CurrencyInfoTableCell from './CurrencyInfoTableCell.vue';
import { AuthorityType, Wallet } from 'ah-api-gateways';
import AddMoneyModal from './AddMoneyModal.vue';
import { WalletsPaginatedQuery } from 'ah-api-gateways';
import { from } from 'rxjs';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useManagedListing, defineUseManagedListingProps, UseManagedListingEmits } from 'ah-common-lib/src/listing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useWalletState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { FundsInTransitIcon } from 'ah-common-lib/src/icons/components';
import TextBlockWithOverflowTooltip from 'ah-common-lib/src/text/TextBlockWithOverflowTooltip.vue';

const props = defineProps({
  walletId: {
    type: String,
  },
  narrow: {
    type: [Boolean, String],
    default: false,
  },
  hideHeaders: {
    type: [Boolean, String],
    default: false,
  },
  hidePagination: {
    type: [Boolean, String],
    default: false,
  },
  config: {
    type: Object as PropType<{ tableFields: any[] }>,
    default: () => ({
      tableFields: [
        {
          header: 'Currency',
          key: 'currency',
        },
        {
          header: 'In Transit',
          key: 'inTransit',
        },
        {
          header: 'Balance',
          key: 'balance',
        },
        {
          header: 'Fee Warning',
          key: 'feeWarning',
          colStyle: { width: '2em' },
          class: 'fee-warning',
        },
      ],
    }),
  },
  ...defineUseManagedListingProps<Wallet>(),
});

const requestManager = useRequestManager({
  exposeToParent: ['loadFeesInfo'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadFeesInfo') {
      loadFees();
    }
  },
});

const onBehalfOfClient = useOnBehalfOf();

const walletState = useWalletState();

const withOutstandingFees = computed(() => feesInfo.value && feesInfo.value.debtCumulative > 0);

interface WalletEmits extends UseManagedListingEmits<Wallet> {}

const emit = defineEmits<WalletEmits>();

const canSeeFundingDetails = computed(() =>
  walletState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_FUNDING_DETAILS)
);

const feesInfo = computed(() => {
  return isPartnerWalletListing.value
    ? walletState.store.useWalletsStore().getPartnerFeesInfo()
    : walletState.store.useWalletsStore().getClientFeesInfo(ownerId.value!);
});

const isPartnerWalletListing = computed(
  () => !onBehalfOfClient.value && !walletState.store.useAuthStore().isClientUser
);

const ownerId = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.id;
  }
  return (
    walletState.store.useAuthStore().loggedInIdentity?.client?.id ||
    walletState.store.useAuthStore().loggedInIdentity?.partner?.id
  );
});

const managedListing = useManagedListing<Wallet>({
  loadDataRequest(query?: WalletsPaginatedQuery) {
    return from(
      walletState.store.useWalletsStore().listWallets({
        query,
        owner: {
          isPartner: isPartnerWalletListing.value,
          id: ownerId.value,
        },
      })
    );
  },
  emit,
  props,
  fields: computed(() => props.config.tableFields),
  reqManager: requestManager.manager,
});

function loadFees() {
  if (walletState.store.useAuthStore().isLoggedIn) {
    requestManager.manager.newPromise(
      'loadFeesInfo',
      isPartnerWalletListing.value
        ? walletState.store.useWalletsStore().loadPartnerFeesInfo({ force: false })
        : walletState.store.useWalletsStore().loadClientFeesInfo({ force: false, clientId: ownerId.value! })
    );
  }
}

watch(ownerId, loadFees);

function rowClass(item: Wallet | null) {
  if (item && feesInfo.value && walletState.mediaQuery.is('smDown'))
    return withOutstandingFees.value && item.currency === feesInfo.value.debtCurrency ? 'highlight-left' : '';
  return '';
}

defineExpose({ loadData: () => managedListing.loadData() });
</script>

<template>
  <PaginatedTable
    fixed
    hover
    nowrap
    animateCols
    :class="[
      'wallets-table',
      {
        'hide-headers': hideHeaders !== false,
        'hide-pagination': hidePagination !== false,
      },
    ]"
    items-label="wallets"
    v-on="{ ...managedListing.listeners, ...$listeners }"
    v-bind="{ ...managedListing.bindings, ...$attrs, config }"
    :tbody-tr-class="rowClass"
    :stacked="$ahWalletState.mediaQuery.is('smDown')"
  >
    <template #cell(currency)="{ item }">
      <CurrencyInfoTableCell :currency="item.currency" :showLabels="narrow === false" />
    </template>
    <template #cell(inTransit)="{ item }">
      <FundsInTransitIcon
        class="d-block ml-auto mr-auto"
        v-if="item.tradeBuyAmountInTransit > 0 || item.tradeSellAmountInTransit > 0"
        v-b-tooltip="'Amount in Transit'"
      />
    </template>
    <template #cell(balance)="{ item }">
      <TextBlockWithOverflowTooltip class="balance-amount" :text-content="formatCurrencyValue(item.balance)" />
    </template>
    <template #cell(add-money)="{ item }">
      <div class="add-money">
        <AddMoneyModal :clientId="ownerId" #default="{ show }" :wallet="item" v-if="canSeeFundingDetails">
          <a @click="show" class="add-money-link link"
            >Add money <InfoTooltip :text="$ahWalletState.i18n.t('tooltips.addMoney')" /><IconArrowRight
          /></a>
        </AddMoneyModal>
      </div>
    </template>

    <template #headInner(feeWarning)> {{ null }}</template>
    <template #cell(feeWarning)="{ item }">
      <div
        class="text-content"
        v-if="
          withOutstandingFees &&
          feesInfo &&
          item.currency === feesInfo.debtCurrency &&
          !$ahWalletState.mediaQuery.is('smDown')
        "
      >
        <IconInfoCircle v-b-tooltip="'Outstanding fees'" class="status-icon danger" />
      </div>
    </template>
  </PaginatedTable>
</template>

<style lang="scss" scoped>
.balance-amount {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.custom-control {
  padding-bottom: 0.25rem;

  .custom-control-input:checked ~ .custom-control-label {
    font-weight: 500;
  }
}

.wallets-table.hide-headers ::v-deep {
  thead,
  .b-table-top-row {
    display: none;
  }
}

.wallets-table.hide-pagination ::v-deep {
  .table-actions {
    display: none;
  }
}

.wallets-table ::v-deep {
  tr {
    .add-money {
      display: none;
      text-align: right;

      .add-money-link {
        font-size: 0.8em;
        @include themedTextColor;
      }
    }

    &:hover .add-money {
      display: block;
    }

    [data-label]::before {
      width: 35% !important;
    }
  }
}

.wallets-table.table-stacked ::v-deep {
  .fee-warning {
    // In mobile screens:
    // - Remove fee warning label
    // - Set it's position to aline with last tr
    // - prevent background on hover from interfering with other cell's rendering

    position: absolute !important;
    bottom: 12px;
    background: none !important;
    .p-column-title {
      display: none !important;
    }
  }

  td > div {
    padding: 0 !important;
  }

  td:first-child .p-column-title {
    min-height: 3em;
    display: flex !important;
    align-items: center;
  }
}

.text-content {
  padding-left: 3em;
  position: relative;
  padding-top: 1em;
  margin-bottom: 1em;

  .status-icon {
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    font-size: 1.5em;

    &.success {
      @include themedTextColor($color-success);
    }

    &.danger {
      @include themedTextColor($color-danger);
    }
  }
}

::v-deep {
  .selectable-icon-holder {
    right: 0px !important;
    top: 18px !important;
  }

  .highlight-left td:first-child:after {
    @include phablet {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 8px solid transparent;
      border-color: $red;
    }
  }
}
</style>
