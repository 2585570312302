var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('TileCard',{attrs:{"tileTitle":"Invoice Limits"}},[_c(_setup.ValidatedForm,{attrs:{"fm":_setup.invoiceDetailsForm.form,"validation":_setup.invoiceDetailsForm.validation},on:{"update:validation":function($event){return _vm.$set(_setup.invoiceDetailsForm, "validation", $event)}}})],1),_c('TileCard',{attrs:{"tileTitle":"Customer Payment"}},[_c('VRow',[_c('VCol',{attrs:{"cols":"12","lg":"4"}},[_c(_setup.ValidatedForm,{attrs:{"fm":_setup.customerPaymentForm.form,"validation":_setup.customerPaymentForm.validation},on:{"update:validation":function($event){return _vm.$set(_setup.customerPaymentForm, "validation", $event)}}})],1),_c('VCol',{staticClass:"offset-lg-1 mb-2",attrs:{"cols":"6","lg":"3","alignSelf":"end"}},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_setup.dividedLabel)+" ")]),_c('VRow',{attrs:{"alignV":"end"}},[_c('VCol',[_c('span',[_vm._v(" "+_vm._s(_setup.sellCurrency)+" "),(
                  _setup.isValid &&
                  (_setup.requestManager.requestStates.getCutoffTime === 'pending' ||
                    _setup.requestManager.requestStates.reloadPrices === 'pending' ||
                    _setup.requestManager.requestStates.createVanillaOptions === 'pending')
                )?_c('LoadingIcon'):_c('strong',[_c(_setup.FormattedCurrency,{attrs:{"value":_setup.dividedAmount,"mainFontSize":24,"decimalFontSize":16}})],1)],1)])],1)],1),_c('VCol',{staticClass:"mb-2",attrs:{"cols":"6","lg":"3","alignSelf":"end"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Total Amount")]),_c('VRow',{attrs:{"alignV":"end"}},[_c('VCol',[_c('span',[_vm._v(" "+_vm._s(_setup.sellCurrency)+" "),(
                  _setup.isValid &&
                  (_setup.requestManager.requestStates.getCutoffTime === 'pending' ||
                    _setup.requestManager.requestStates.reloadPrices === 'pending' ||
                    _setup.requestManager.requestStates.createVanillaOptions === 'pending')
                )?_c('LoadingIcon'):_c('strong',[_c(_setup.FormattedCurrency,{attrs:{"value":_setup.sellAmount,"mainFontSize":24,"decimalFontSize":16}})],1)],1)])],1)],1)],1),_c('VRow',[_c('VCol',{staticClass:"mb-2 mb-lg-0",attrs:{"cols":"12","lg":"3"}},[_c('DataRow',{attrs:{"cols":"7","label":"Conversion Rate"}},[(
              _setup.isValid &&
              (_setup.requestManager.requestStates.getCutoffTime === 'pending' ||
                _setup.requestManager.requestStates.reloadPrices === 'pending')
            )?_c('LoadingIcon'):_c('span',[_vm._v(_vm._s(_setup.formatCurrencyValue(_setup.strikeRate) ?? '-'))])],1)],1),(_setup.targetDate)?_c('VCol',{attrs:{"cols":"7"}},[_c(_setup.UpdatingInTimer,{attrs:{"target-date":_setup.targetDate,"loading":_setup.isValid &&
            (_setup.requestManager.requestStates.getCutoffTime === 'pending' ||
              _setup.requestManager.requestStates.reloadPrices === 'pending' ||
              _setup.requestManager.requestStates.createVanillaOptions === 'pending')},on:{"timer-end":_setup.requestNewPrice}})],1):_vm._e()],1)],1),_c('TileCard',{attrs:{"tileTitle":"Payment Request Details"}},[_c(_setup.ValidatedForm,{attrs:{"fm":_setup.paymentRequestForm.form,"validation":_setup.paymentRequestForm.validation},on:{"update:validation":function($event){return _vm.$set(_setup.paymentRequestForm, "validation", $event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }