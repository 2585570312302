<script setup lang="ts">
import { makeFormModel, getChildModel, setState, submitForm, batchSetState } from 'ah-common-lib/src/form/helpers';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import TradePriceExchangeRate from '../info/TradePriceExchangeRate.vue';
import FieldWalletInfoOrErrors from 'ah-wallets/src/components/FieldWalletInfoOrErrors.vue';
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { AmountType, getSellCcy, Trade } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { financialAmountField } from 'ah-common-lib/src/form/models';
import {
  DrawdownDetails,
  getBuyCcy,
  getFixedSideCcy,
  TimeFrames,
  CutoffTimeModel,
  DrawdownQuotePriceResponse,
  FxOperation,
} from 'ah-api-gateways/models';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useTradeState } from '../..';
import { catchError, debounceTime, switchMap, tap, mergeMap } from 'rxjs/operators';
import { EMPTY, of, Subject, from } from 'rxjs';
import { ApiError, PricingEngineErrorCodes } from 'ah-requests';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import UpdatedDate from 'ah-common-lib/src/common/components/time/UpdatedDate.vue';
import { pricingEngineError, PricingEngineErrorOptions } from './pricingEngineChecks';

// List of Pricing Engine Errors that should show the Partner's contacts
const pricingEngineErrorsNeedingSupport = [
  PricingEngineErrorCodes.AMOUNT_OUT_OF_BOUNDS,
  PricingEngineErrorCodes.OPTION_PREMIUM_LESS_THAN_THRESHOLD,
  PricingEngineErrorCodes.OPTION_PREMIUM_GREATER_THAN_THRESHOLD,
  PricingEngineErrorCodes.UNAVAILABLE_CURRENCY_PAIR,
  PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE,
  PricingEngineErrorCodes.BASIS_POINTS_BELOW_PARTNER_PROFIT_LIMITS,
  PricingEngineErrorCodes.BASIS_POINTS_ABOVE_PARTNER_PROFIT_LIMITS,
  PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE,
];

const props = withDefaults(
  defineProps<{
    trade: Trade;
    drawdownDetails: DrawdownDetails | null;
    drawdownPrice: DrawdownQuotePriceResponse | null;
    autoSetMax?: boolean | string;
    responseTimeOffset?: number;
  }>(),
  {
    autoSetMax: false,
  }
);

const emit = defineEmits<{
  (e: 'update:drawdownDetails', value: DrawdownDetails | null): void;
  (e: 'update:drawdownPrice', value: DrawdownQuotePriceResponse | null): void;
  (e: 'update:responseTimeOffset', value: number | null): void;
}>();

const peError = ref<ApiError | null>(null);

const hasErrors = ref<boolean>(false);

const drawdownDetailsSubject = new Subject<{ amount: number; amountType: AmountType }>();

const drawdownForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'drawdownForm',
    fieldType: 'form',
    fields: [
      financialAmountField('sellAmount', '', {
        hideErrorMessages: true,
        errorMessages: { required: 'Value is required' },
        required: true,
      }),
      financialAmountField('buyAmount', '', {
        hideErrorMessages: true,
        errorMessages: { required: 'Value is required' },
        required: true,
      }),
    ],
  }),
  validation: null,
});

const requestManager = useRequestManager();

const onBehalfOfClient = useOnBehalfOf();

const cutoffTime = ref<CutoffTimeModel | null>(null);

const tradeState = useTradeState();

const valid = computed(() => {
  if (!drawdownForm.validation) return false;
  return !drawdownForm.validation.$invalid && !!props.drawdownPrice;
});

const sellCcy = computed(() => getSellCcy(props.trade));

const buyCcy = computed(() => getBuyCcy(props.trade));

const fixedAmountType = ref<AmountType | null>(null);

const drawdownDetails = computed<DrawdownDetails | null>(() => {
  const fixedSideAmountType = fixedAmountType.value || getFixedSideCcy(props.trade).amountType;
  return {
    amount: drawdownForm.form[getFormKey(fixedSideAmountType)] || 0,
    amountType: fixedSideAmountType,
    buyCurrency: buyCcy.value.currency,
    sellCurrency: sellCcy.value.currency,
    timeFrame: {
      targetDate: cutoffTime.value?.firstConversionCutoffDatetime ?? new Date().toISOString(),
      targetTimeFrame: TimeFrames.OTHER,
      isForward: false,
    },
  };
});

const remainingSellAmount = computed(() => sellCcy.value.remainingClientAmount - drawdownForm.form.sellAmount);

const remainingBuyAmount = computed(() => buyCcy.value.remainingClientAmount - drawdownForm.form.buyAmount);

const isClient = computed(() => tradeState.store.useAuthStore().isClientUser);

const clientId = computed(() => {
  return onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id;
});

const tradingDeskContactHTMLMessage = computed(() => {
  const tradingDeskEmail = tradeState.theme.tradingDeskEmail;
  const tradingDeskPhoneNumber = tradeState.theme.tradingDeskPhoneNumber;

  if (tradingDeskEmail || tradingDeskPhoneNumber) {
    let out = 'Please contact our trading desk on ';
    if (tradingDeskPhoneNumber) {
      out += getPhoneNumber(tradingDeskPhoneNumber) || tradingDeskPhoneNumber;
      if (tradingDeskEmail) {
        out += ' or ';
      }
    }
    if (tradingDeskEmail) {
      out += `<a target="_blank" href="mailto:${tradingDeskEmail}">${tradingDeskEmail}</a>`;
    }
    out += '.';

    return out;
  }
  return '';
});

function getFormKey(amountType: AmountType, mirror = false) {
  if (mirror) {
    return `${amountType === AmountType.BUY ? 'sell' : 'buy'}Amount`;
  }
  return `${amountType === AmountType.BUY ? 'buy' : 'sell'}Amount`;
}

const sellAmountModel = computed(() => getChildModel(drawdownForm.form, 'sellAmount')!);

const buyAmountModel = computed(() => getChildModel(drawdownForm.form, 'buyAmount')!);

function touchForms() {
  submitForm(drawdownForm.validation!);
}

function cleanPlaceholders() {
  batchSetState(drawdownForm.form, 'placeholder', ['sellAmount', 'buyAmount'], '');
}

function generatePricing(amount: number, amountType: AmountType) {
  const calculedFormKey = getFormKey(amountType);
  const calculatingFormKey = getFormKey(amountType, true);

  // Clean all fields
  cleanPlaceholders();
  drawdownForm.form[calculatingFormKey] = '';
  drawdownForm.validation!.$reset();

  if (drawdownForm.form[calculedFormKey]) {
    drawdownDetailsSubject.next({ amount, amountType });
  }
}

function setToMax(amountType: AmountType) {
  if (amountType === AmountType.BUY) {
    drawdownForm.form.buyAmount = buyCcy.value.remainingClientAmount;
    generatePricing(drawdownForm.form.buyAmount, AmountType.BUY);
  } else {
    drawdownForm.form.sellAmount = sellCcy.value.remainingClientAmount;
    generatePricing(drawdownForm.form.sellAmount, AmountType.SELL);
  }
}

function setErrorMessage(message: string, fieldName = 'sellAmount') {
  let field = getChildModel(drawdownForm.form, fieldName);
  if (field) {
    setState(field, 'errors', [
      {
        name: 'customError',
        error: message,
      },
    ]);
  }
}

function clearErrorMessages() {
  setState(drawdownForm.form, 'errors', [], true);
  hasErrors.value = false;
}

function checkPricingEngineValidation(error: ApiError) {
  let options: PricingEngineErrorOptions | null = null;
  options = {
    sellCurrency: sellCcy.value.currency,
    buyCurrency: buyCcy.value.currency,
    tradingDeskContactHTMLMessage: tradingDeskContactHTMLMessage.value,
    isClientUser: isClient.value,
  };

  let content = pricingEngineError(error, pricingEngineErrorsNeedingSupport, options);

  if (content) {
    batchSetState(drawdownForm.form, 'errors', {
      ['buyAmount']: [],
      ['sellAmount']: [
        {
          name: 'peError',
          html: true,
          error: content,
        },
      ],
    });
  } else {
    batchSetState(drawdownForm.form, 'unexpectedError', {
      ['sellAmount']: true,
      ['buyAmount']: false,
    });
    tradeState.toast.error('An error occured while calculating trade price. Please try again later.');
  }
}

function isOverLimit(amountType: AmountType) {
  const ccy = amountType === AmountType.BUY ? buyCcy.value : sellCcy.value;
  return drawdownForm.form[getFormKey(amountType)] > ccy.remainingClientAmount;
}

function loadCutoffTime() {
  const currencyPair = `${drawdownDetails.value!.sellCurrency}${drawdownDetails.value!.buyCurrency}`;
  if (clientId.value) {
    requestManager.manager
      .sameOrCancelAndNew(
        'loadCutoffTime',
        tradeState.services.pricingEngine.getCutoffTime(
          currencyPair,
          clientId.value,
          FxOperation.DRAWDOWN,
          props.trade.id,
          onBehalfOfClient.value?.id
        ),
        currencyPair
      )
      .subscribe((val) => {
        cutoffTime.value = val;
      });
  }
}

function loadDrawdownPrice(drawdownAmount: number, amountType: AmountType) {
  peError.value = null;
  if (isOverLimit(amountType)) {
    setErrorMessage('Exceeded maximum limit', getFormKey(amountType));
    hasErrors.value = true;
    return of(null);
  }

  loadCutoffTime();

  setState(getChildModel(drawdownForm.form, getFormKey(amountType, true))!, 'placeholder', 'Calculating...');

  let date: Date;

  return from(requestManager.manager.waitForRequests(['loadCutoffTime'])).pipe(
    mergeMap(() => {
      date = new Date();

      return requestManager.manager.cancelAndNew(
        'getPrices',
        tradeState.services.pricingEngine.createDrawdownQuote(
          props.trade.id,
          drawdownAmount,
          amountType,
          onBehalfOfClient.value?.id,
          {
            options: {
              errors: {
                silent: true,
              },
            },
          }
        )
      );
    }),
    tap((res) => {
      emit('update:drawdownPrice', res);
      emit('update:responseTimeOffset', date.getTime() - new Date(res.priceRequestedTimestamp).getTime());
    }),
    catchError((e) => {
      cleanPlaceholders();
      if (e.response.status === 500) {
        batchSetState(drawdownForm.form, 'unexpectedError', {
          ['sellAmount']: true,
          ['buyAmount']: false,
        });
        tradeState.toast.error('An error occured while calculating trade price. Please try again later.');
      }
      checkPricingEngineValidation(e.response.data);
      emit('update:drawdownPrice', null);
      return EMPTY;
    })
  );
}

/**
 * When refreshing a drawdown price, we DO NOT use the drawdown details
 * (as small cent increments may lead to price differences adding up)
 *
 * Instead, we use the current price, and calculate using the original Trade's fixed currency instead
 * (as this guarantees the price will be consistent)
 */
function refreshPricing() {
  if (props.drawdownPrice) {
    generatePricing(getFixedSideCcy(props.drawdownPrice).clientAmount, getFixedSideCcy(props.drawdownPrice).amountType);
  }
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    clearErrorMessages();
    if (event.field.$path.includes('sellAmount')) {
      generatePricing(drawdownForm.form.sellAmount, AmountType.SELL);
      fixedAmountType.value = AmountType.SELL;
    } else {
      generatePricing(drawdownForm.form.buyAmount, AmountType.BUY);
      fixedAmountType.value = AmountType.BUY;
    }
  }
}

watch(
  () => props.autoSetMax,
  () => {
    if (props.autoSetMax) {
      setTimeout(() => setToMax(AmountType.SELL));
    }
  },
  { immediate: true }
);

watch([drawdownDetails, props.trade], () => emit('update:drawdownDetails', drawdownDetails.value), { immediate: true });

watch(
  () => props.trade,
  () => loadCutoffTime,
  { immediate: true }
);

watch(
  () => props.drawdownPrice,
  () => {
    if (props.drawdownPrice) {
      drawdownForm.form[getFormKey(props.drawdownPrice.ccy1.amountType)] = props.drawdownPrice?.ccy1.clientAmount;
      drawdownForm.form[getFormKey(props.drawdownPrice.ccy2.amountType)] = props.drawdownPrice?.ccy2.clientAmount;
    }
  },
  { immediate: true }
);

let drawdownDetailsSubscription = drawdownDetailsSubject
  .pipe(
    tap(() => requestManager.manager.cancel('getPrices')),
    debounceTime(700),
    switchMap(({ amount, amountType }) => loadDrawdownPrice(amount, amountType))
  )
  .subscribe();

onBeforeUnmount(() => drawdownDetailsSubscription!.unsubscribe());

defineExpose({ touchForms, setErrorMessage, valid });
</script>

<template>
  <div>
    <UpdatedDate
      v-if="props.drawdownPrice && !peError && !hasErrors"
      :date="props.drawdownPrice.priceRequestedTimestamp"
      dateStyle="short"
      :loading="
        requestManager.manager.requestStates.loadCutoffTime === 'pending' ||
        requestManager.manager.requestStates.getPrices === 'pending'
      "
      @refresh="refreshPricing"
      class="trade-updated-date"
      account-for-clock-delay
    />
    <h3 class="mb-3">Drawdown amount <InfoTooltip :text="$t('tooltips.drawdownAmount')" /></h3>
    <ValidatedForm :fm="drawdownForm.form" :validation.sync="drawdownForm.validation" @form-event="onFormEvent">
      <template #drawdownForm.sellAmount:prepend>
        <label class="mr-2 mb-0"> Selling: </label> {{ sellCcy.currency }}
      </template>
      <template #drawdownForm.sellAmount:after>
        <FieldWalletInfoOrErrors
          v-if="drawdownForm.validation"
          class="wallet-info"
          :field="drawdownForm.validation.sellAmount"
          :model="sellAmountModel"
          :walletCurrency="sellCcy.currency"
          hideOverdraft
        />
      </template>

      <template #drawdownForm.sellAmount:append>
        <div>
          <VButton blurOnClick class="btn-stroked" @click="setToMax(AmountType.SELL)"> Maximum </VButton>
          <p class="amount-information">Max: {{ formatCurrencyValue(sellCcy.remainingClientAmount) }}</p>
        </div>
      </template>

      <template #drawdownForm.buyAmount:prepend>
        <label class="mr-2 mb-0"> Buying: </label> {{ buyCcy.currency }}
      </template>
      <template #drawdownForm.buyAmount:append>
        <div>
          <VButton blurOnClick class="btn-stroked" @click="setToMax(AmountType.BUY)"> Maximum </VButton>
          <p class="amount-information">Max: {{ formatCurrencyValue(buyCcy.remainingClientAmount) }}</p>
        </div>
      </template>
      <template #drawdownForm.buyAmount:after>
        <FieldWalletInfoOrErrors
          v-if="drawdownForm.validation"
          class="wallet-info"
          :field="drawdownForm.validation.buyAmount"
          :model="buyAmountModel"
          :walletCurrency="buyCcy.currency"
          hideOverdraft
        />
      </template>
    </ValidatedForm>

    <Transition name="slide-and-fade" appear>
      <div v-if="valid">
        <TradePriceExchangeRate v-if="drawdownPrice" :tradePriceResponse="drawdownPrice" />

        <DataRow class="my-3" cols="4" label="Remaining notionals">
          <div class="mb-2">{{ sellCcy.currency }} {{ formatCurrencyValue(remainingSellAmount) }}</div>
          <div>{{ buyCcy.currency }} {{ formatCurrencyValue(remainingBuyAmount) }}</div>
        </DataRow>
      </div>
    </Transition>
    <div class="text-muted">
      It is advisable that you have the selling amount in your wallet prior to executing your drawdown.
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .trade-updated-date {
    position: absolute;
    top: 24px;
    right: 20px;
    font-size: 14px;
    @include themedTextColor($color-text-secondary);
  }
  .field-group-wrapper {
    margin-bottom: 0;
    .error-icon {
      left: 6.5em;
    }
    .wallet-info {
      margin-top: 0.3em;
      margin-bottom: 3em;
      padding-left: 8em;
      * {
        font-size: $font-size-sm;
      }
    }
    .field-group-field-input {
      max-width: 13rem;
      margin-left: 2rem;
      margin-right: 2rem;
      border: 1px solid !important;
      @include themedBorderColor($color-input-border, $color-dark-input-border, '', ' !important');
      border-radius: 4px !important;

      .wallet-info {
        position: absolute;
        bottom: 0;
        left: 4rem;
        font-size: $font-size-sm;
      }
    }

    .input-group-prepend,
    .input-group-append {
      display: flex;
      align-items: center;
      .amount-information {
        position: absolute;
        font-size: $font-size-sm;
        bottom: -2.5rem;
        @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
      }
    }
  }
}
</style>
