<script lang="ts" setup>
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { usePlaidLink, preloadPlaid } from 'ah-common-lib/src/plaid';
import IntegrationsListing from './IntegrationsListing.vue';
import { getServices } from '@/app/services';
import { VButton } from 'ah-common-lib/src/common/components';
import { useToast } from 'ah-common-lib/src/toast';
import { computed, ref } from 'vue';
import config from '@/config';
import { format } from 'date-fns';
import { IntegrationState, IntegrationType } from 'ah-api-gateways';

const props = defineProps({
  includeRevoked: {
    type: [Boolean, String],
    default: false,
  },
});

const reqManager = useRequestManager().manager;
const services = getServices();
const toast = useToast();

const integrationsListing = ref<InstanceType<typeof IntegrationsListing>>();

const listConfig = {
  tableFields: [
    {
      header: 'State',
      key: 'state',
    },
    {
      header: 'Date added',
      key: 'createdAt',
    },
    {
      header: '',
      key: 'actions',
    },
  ],
};

const listFilter = computed(() => {
  const out: Record<string, any> = {
    integration: IntegrationType.PLAID,
  };

  if (props.includeRevoked === false) {
    out.state = IntegrationState.ACTIVE;
  }

  return out;
});

preloadPlaid();

function addPlaidIntegration() {
  reqManager.cancelAndNew('getPlaidToken', services.integrations.requestPlaidLinkToken()).subscribe((tokenData) => {
    const plaidLink = usePlaidLink({
      token: tokenData.linkToken,
      onExit: () => {
        plaidLink.destroy();
      },
      onSuccess: (publicToken) => {
        reqManager
          .new('exchangePlaidToken', services.integrations.exchangePlaidPublicToken(publicToken))
          .subscribe(() => {
            toast.success('Plaid integration sucessfully added');
            integrationsListing.value?.loadData();
            plaidLink.destroy();
          });
      },
    });

    plaidLink.open();
  });
}
</script>
<template>
  <div>
    <IntegrationsListing ref="integrationsListing" :config="listConfig" :filter="listFilter">
      <template #cell(createdAt)="{ item }">
        {{ format(new Date(item.createdAt), 'dd MMM yyyy HH:mm') }}
      </template>
    </IntegrationsListing>
    <p class="text-right">
      <VButton @click="addPlaidIntegration" :loading="reqManager.anyPending">+ Add Plaid Integration</VButton>
    </p>
    <p class="text-right text-secondary text-small font-italic" v-if="config.plaidInSandboxMode">
      In Plaid Sandbox mode, all mock bank accounts will allow using the username/password <b>user_good/pass_good</b>
    </p>
  </div>
</template>
