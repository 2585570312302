import { CreatableObject, DeletableObject } from '../rest';

export enum IntegrationType {
  PLAID = 'PLAID',
}

export enum IntegrationState {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
}

export const integrationStateLabels: Record<IntegrationState, string> = {
  ACTIVE: 'Active',
  REVOKED: 'Revoked',
};

export interface ClientIntegrationFilters {
  id?: string[];
  state?: IntegrationState[];
  clientId?: string[];
  partnerId?: string[];
  integration?: IntegrationType[];
  resourceKey?: string[];
}

export interface ClientIntegration extends DeletableObject, CreatableObject {
  id: string;
  state: IntegrationState;
  clientId: string;
  integration: IntegrationType;
  resourceKey: string;
  metadata: string;
}
