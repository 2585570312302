import { ComplianceErrorCodes, HttpError } from 'ah-requests';
import { addMonths, differenceInYears, isAfter, isBefore, parse } from 'date-fns';

export interface AddressBaseData {
  addressLine: string;
  city: string;
  countryCode?: string;
  postalCode: string;
  stateOrProvince: string;
}

export interface Address extends AddressBaseData {
  id?: number;
  nationality?: string;
}

export interface AddressHistoryItem extends AddressBaseData {
  residingTo: string;
  residingFrom: string;
}

export type CurrentAddressHistoryItem = Omit<AddressHistoryItem, 'residingTo'>;

export enum EntityTypeAddress {
  UBO = 'UBO',
  INDIVIDUAL = 'INDIVIDUAL',
}
export interface EntityAddressHistory {
  entityId: string;
  type: EntityTypeAddress;
  currentAddress: CurrentAddressHistoryItem;
  previousAddresses: AddressHistoryItem[];
}
export interface EntityAddressHistoryResponse {
  individualId: string;
  uboId: string;
  clientId: string;
  partnerId: string;
  addresses: AddressHistoryItem[];
}

export interface AddressHistoryListFilterParams {
  id?: string;
  uboId?: string;
  individualId?: string;
  clientId?: string;
  partnerId?: string;
}

/**
 * Minimum age required when requiring a full address history (when user is in UK)
 */
export const MIN_ADDRESS_HISTORY_AGE = 3;

export function isAddressHistoryRequired(countryCode?: string) {
  return countryCode === 'GB';
}

export function getAddressHistoryAge(options: {
  currentAddress: Partial<CurrentAddressHistoryItem>;
  previousAddresses?: Partial<AddressHistoryItem>[];
}): number {
  function parseDate(dateStr: string) {
    return parse(dateStr, 'yyyy-MM-dd', new Date());
  }

  let residingFromDate = options.currentAddress.residingFrom
    ? parseDate(options.currentAddress.residingFrom)
    : new Date();

  if (options.previousAddresses && options.previousAddresses.length > 0) {
    for (const address of options.previousAddresses) {
      const currentFromDate = address.residingFrom ? parseDate(address.residingFrom) : residingFromDate;

      if (currentFromDate.valueOf() < residingFromDate.valueOf()) {
        residingFromDate = currentFromDate;
      }
    }
  }

  return differenceInYears(new Date(), residingFromDate);
}

/**
 * Check validity of Address history
 * Returns an object containing all possible validations: gaps, overlap and age
 * Values are true if valid, false if not
 *
 * Currently assumes a default age requirement of 3 years (as it is the only value currently used)
 */
export function checkAddressHistoryValidity(options: {
  currentAddress: Partial<CurrentAddressHistoryItem>;
  previousAddresses?: Partial<AddressHistoryItem>[];
  minYears?: number;
}): { gaps: boolean; overlaps: boolean; age: boolean } {
  const minYears = options.minYears ?? 3;
  const out = {
    gaps: true,
    overlaps: true,
    age: getAddressHistoryAge(options) >= minYears,
  };

  function parseDate(dateStr: string) {
    return parse(dateStr, 'yyyy-MM-dd', new Date());
  }

  const sortedAddresses = [...(options.previousAddresses || []), options.currentAddress].sort(
    (a, b) => parseDate(b.residingFrom!).getTime() - parseDate(a.residingFrom!).getTime()
  );

  for (let i = 0; i < sortedAddresses.length - 1; i++) {
    const currentAddress = sortedAddresses[i];
    const nextAddress = sortedAddresses[i + 1] as AddressHistoryItem;

    const currentResidingFromDate = parseDate(currentAddress.residingFrom!);
    const nextResidingToDate = nextAddress.residingTo
      ? parseDate(nextAddress.residingTo)
      : parseDate(currentAddress.residingFrom!);

    if (isAfter(currentResidingFromDate, addMonths(nextResidingToDate, 1))) {
      out.gaps = false;
    }
    if (isBefore(currentResidingFromDate, nextResidingToDate)) {
      out.overlaps = false;
    }
  }

  return out;
}
export function getAddressHistoryError(error: HttpError) {
  const errorCodes: ComplianceErrorCodes[] = [
    ComplianceErrorCodes.ADDRESS_HISTORY_TEMPORAL_GAPS,
    ComplianceErrorCodes.ADDRESS_HISTORY_YEARS,
    ComplianceErrorCodes.ADDRESS_HISTORY_MISSING_RESIDING_FROM,
    ComplianceErrorCodes.INVALID_STATE_OR_PROVINCE,
  ];

  if (error.response?.data.code && errorCodes.includes(error.response.data.code as ComplianceErrorCodes)) {
    return error.response.data.message;
  }
  return '';
}
