<script setup lang="ts">
import { computed, PropType, reactive, ref, watch } from 'vue';
import { AmountType, PaginatedResponse, VanillaPriceResponse } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import FormattedCurrency from 'ah-trades/src/components/limitsAndCollateral/FormattedCurrency.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import CountdownTimer from 'ah-common-lib/src/common/components/CountdownTimer.vue';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useAuthStore } from '@/app/store/authStore';
import { getServices } from '@/app/services';
import PaymentScheduleListing, { OptionPaymentSchedule } from './PaymentScheduleListing.vue';
import { paymentScheduleTableFields } from './tableFields/paymentScheduleTableFields';
import { addDays, addMonths, addQuarters, format } from 'date-fns';
import { PaymentFormDetails, PaymentScheduleFrequency } from '@/app/models/payments';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { checkboxField } from 'ah-common-lib/src/form/models';

const props = defineProps({
  solution: {
    type: Object as PropType<VanillaPriceResponse>,
    required: true,
  },
  model: {
    type: Object as PropType<PaymentFormDetails>,
    required: true,
  },
  disabledDates: {
    type: Array as PropType<String[]>,
    required: true,
  },
  isBooking: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits({
  'update:solution': (_solution: VanillaPriceResponse) => true,
  'update:isLoading': (_isLoading: boolean) => true,
  'update:isInvalid': (_isInvalid: boolean) => true,
  back: () => true,
});

const TERMSHEET_FILE_URL = 'https://www.alt21.com/wp-content/uploads/2021/06/HG-Termsheet-v1.pdf';

const formatter = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const requestManager = useRequestManager().manager;

const onBehalfOfClient = useOnBehalfOf();

const services = getServices();

const authStore = useAuthStore();

const clientId = computed(() => authStore.loggedInIdentity?.client?.id ?? onBehalfOfClient.value?.id);

const isRequestingNewPrice = ref<boolean>(false);

const pause = computed(() => isRequestingNewPrice.value || props.isBooking);

const sellCurrency = computed(() =>
  props.solution.ccy1.amountType === AmountType.SELL ? props.solution.ccy1.currency : props.solution.ccy2.currency
);

const buyCurrency = computed(() =>
  props.solution.ccy1.amountType === AmountType.BUY ? props.solution.ccy1.currency : props.solution.ccy2.currency
);

const sellAmount = computed(() =>
  props.solution.ccy1.amountType === AmountType.SELL
    ? props.solution.ccy1.clientAmount + props.solution.ccy1.optionsCurrencyData.totalPremium
    : props.solution.ccy2.clientAmount + props.solution.ccy2.optionsCurrencyData.totalPremium
);

const totalPremium = computed(() =>
  props.solution.ccy1.amountType === AmountType.SELL
    ? props.solution.ccy1.optionsCurrencyData.totalPremium
    : props.solution.ccy2.optionsCurrencyData.totalPremium
);

const buyAmount = computed(() =>
  props.solution.ccy1.amountType === AmountType.BUY
    ? props.solution.ccy1.clientAmount
    : props.solution.ccy2.clientAmount
);

const buyPerFrequency = computed(() => buyAmount.value / props.model.mvpNumPayments);

const sellPerFrequency = computed(() => sellAmount.value / props.model.mvpNumPayments);

const paymentsFrequency = computed(() =>
  props.model.mvpFrequency === PaymentScheduleFrequency.MONTHLY ? 'month' : 'quarter'
);

function createVanillaOption(solution: VanillaPriceResponse) {
  const isPrimaryRate = solution.ccy1.isPrimaryRate === true ? solution.ccy1 : solution.ccy2;
  const isFixedSide = solution.ccy1.isFixedSide === true ? solution.ccy1 : solution.ccy2;
  const sellCurrency = solution.ccy1.amountType === AmountType.SELL ? solution.ccy1.currency : solution.ccy2.currency;
  const buyCurrency = solution.ccy1.amountType === AmountType.BUY ? solution.ccy1.currency : solution.ccy2.currency;

  return {
    sellCurrency: sellCurrency,
    buyCurrency: buyCurrency,
    amount: isFixedSide.clientAmount,
    amountType: isFixedSide.amountType,
    settlementDate: formatDate(new Date(solution.settlementDate), 'yyyy-MM-dd'),
    strikeRate: isPrimaryRate.optionsCurrencyData.selectedStrikeRate,
    clientId: clientId.value,
  };
}

function refreshSolution() {
  isRequestingNewPrice.value = true;
  return requestManager
    .sameOrCancelAndNew(
      'createVanillaOptions',
      services.pricingEngine.createVanillaOptionsQuote(createVanillaOption(props.solution), onBehalfOfClient.value?.id)
    )
    .subscribe((price) => {
      isRequestingNewPrice.value = false;
      emit('update:solution', price);
    });
}

const expirationTime = computed(() => new Date(props.solution.priceExpirationTimestamp));

const config = {
  tableFields: paymentScheduleTableFields,
};

const paymentsSchedule = computed(() => {
  let paymentDate = new Date(props.model.mvpFirstPaymentDate);
  let payments = [
    {
      date: props.model.mvpFirstPaymentDate,
      sellAmount: sellPerFrequency.value,
      buyAmount: buyPerFrequency.value,
      sellCurrency: sellCurrency.value,
      buyCurrency: buyCurrency.value,
    } as OptionPaymentSchedule,
  ];

  for (let i = 1; i < props.model.mvpNumPayments; i++) {
    if (props.model.mvpFrequency === PaymentScheduleFrequency.MONTHLY) {
      paymentDate = addMonths(new Date(props.model.mvpFirstPaymentDate), i);
    } else {
      paymentDate = addQuarters(new Date(props.model.mvpFirstPaymentDate), i);
    }

    while (props.disabledDates.includes(format(paymentDate, 'dd-MM-yyyy'))) {
      paymentDate = addDays(paymentDate, 1);
    }

    payments.push({
      date: paymentDate.toISOString(),
      sellAmount: sellPerFrequency.value,
      buyAmount: buyPerFrequency.value,
      sellCurrency: sellCurrency.value,
      buyCurrency: buyCurrency.value,
    });
  }

  return {
    list: payments,
    pageNumber: 0,
    pageSize: payments.length,
    total: payments.length,
  } as PaginatedResponse<OptionPaymentSchedule>;
});

watch(
  () => requestManager.anyPending,
  () => {
    emit('update:isLoading', requestManager.anyPending);
  }
);

const agreementFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'agreementConfirm',
    fieldType: 'form',
    fields: [
      checkboxField('termsheetAgreed', '', false, {
        required: true,
        errorMessages: {
          mustAccept: 'You must accept the Termsheet Agreement to proceed.',
        },
      }),
    ],
  }),
  validation: null,
});

const isInvalid = computed(() => !!agreementFM.validation?.$invalid);

watch(
  isInvalid,
  () => {
    emit('update:isInvalid', isInvalid.value);
  },
  { immediate: true }
);

function openTermsheetFile() {
  window.open(TERMSHEET_FILE_URL, '_blank');
}
</script>

<template>
  <div>
    <VRow alignH="end" class="mt-lg-0 mt-sm-3">
      <div class="update-quote">
        <span>Refreshing in... </span>
        <CountdownTimer :target-date="expirationTime" :pause="pause" @timer-end="refreshSolution" />
        <VButton class="btn-secondary" @click="refreshSolution">
          <RefreshIcon />
          Refresh rates
        </VButton>
      </div>
    </VRow>
    <VRow>
      <VCol cols="12" lg="6">
        <TileCard tileTitle="Terms">
          <VRow class="mb-3" alignH="between">
            <VCol cols="12" alignSelf="end">
              <h3 class="mb-0">Conversion Rate</h3>
              <VRow alignV="end">
                <VCol>
                  <VRow alignH="between">
                    <span>
                      <LoadingIcon v-if="requestManager.requestStates.createVanillaOptions === 'pending'" />
                      <strong v-else
                        ><span style="font-size: 24px">{{ formatCurrencyValue(model.strikeRate) }}</span></strong
                      ></span
                    >
                    <VButton class="refresh-button secondary" @click="openTermsheetFile">
                      Termsheet
                      <FileIcon class="ml-1" /> </VButton
                  ></VRow>
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <VRow class="mb-3">
            <VCol cols="5" alignSelf="end">
              <h3 class="mb-0">Converting From</h3>
              <VRow alignV="end">
                <VCol>
                  <span>
                    {{ sellCurrency }}
                    <LoadingIcon v-if="requestManager.requestStates.createVanillaOptions === 'pending'" />
                    <strong v-else
                      ><FormattedCurrency :value="sellAmount" :mainFontSize="24" :decimalFontSize="16" /></strong
                  ></span>
                </VCol>
              </VRow>
            </VCol>
            <VCol cols="5" alignSelf="end">
              <h3 class="mb-0">Converting To</h3>
              <VRow alignV="end">
                <VCol>
                  <span>
                    {{ buyCurrency }}
                    <LoadingIcon v-if="requestManager.requestStates.createVanillaOptions === 'pending'" />
                    <strong v-else
                      ><FormattedCurrency :value="buyAmount" :mainFontSize="24" :decimalFontSize="16" /></strong
                  ></span>
                </VCol>
              </VRow>
            </VCol>
          </VRow>

          <VRow>
            <VCol cols="12"><h3 class="disclaimer-title">Costs to you and your customer</h3></VCol>
            <VCol cols="12">
              <ol class="p-0 pl-3">
                <li class="mb-3">
                  This transaction guarantees your receipt of
                  <strong> {{ `${buyCurrency} ${formatCurrencyValue(buyPerFrequency, '-', formatter)}` }}</strong>
                  every {{ paymentsFrequency }} for the duration of the schedule.
                </li>
                <li class="mb-3">
                  The locked-in strike rate means your customer will convert no more than
                  <strong> {{ `${sellCurrency} ${formatCurrencyValue(sellPerFrequency, '-', formatter)}` }}</strong> per
                  {{ paymentsFrequency }}. This value may decrease, depending on market movements, but will never exceed
                  {{
                    `${sellCurrency}
              ${formatCurrencyValue(sellPerFrequency, '-', formatter)}`
                  }}.
                </li>
                <li>
                  Neither you nor your customer pay anything today. ALT21's total premium of
                  <strong> {{ `${sellCurrency} ${formatCurrencyValue(totalPremium, '-', formatter)}` }}</strong> has
                  been evenly distributed across the schedule and included in the 'Customer Pays' amount. If you, as our
                  client, cancel the contract, you will be charged any remaining unpaid premium.
                </li>
              </ol>
            </VCol>
          </VRow></TileCard
        ></VCol
      >

      <VCol cols="12" lg="6">
        <TileCard tileTitle="Schedule">
          <LoadingIcon v-if="requestManager.requestStates.createVanillaOptions === 'pending'" />
          <PaymentScheduleListing v-else-if="paymentsSchedule" :config="config" :data="paymentsSchedule" /> </TileCard
      ></VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <ValidatedForm class="mt-4" :fm="agreementFM.form" :validation.sync="agreementFM.validation">
          <template #agreementConfirm.termsheetAgreed:checkbox-label>
            <span class="text-muted text-small">
              I confirm I’ve read and agree to the
              <a class="link" :href="TERMSHEET_FILE_URL" target="_blank">termsheet</a>
            </span>
          </template>
        </ValidatedForm>
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.disclaimer-title {
  margin-bottom: 0;
  @include themedTextColor($color-primary, $color-dark-primary);
}

.update-quote {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
</style>
